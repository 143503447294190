// @flow
import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Spin, Row, Col, Avatar, Table, Empty, Button, Tooltip } from 'antd';
// import Box from 'ui-box';
import { connect } from 'react-redux';
import moment from 'moment';
import { t } from 'i18next';
import { formValueSelector } from 'redux-form';
import CardWrapper from '../../common/CardWrapper';
import StudyDetailsCardForm from './form/studyDetailsCardForm';
import { ReduxState, StudyDetails } from '../../schemas';
// import { selectStudyDetails } from '../../store/selectors';
import { STUDY_DETAILS_CARD_FORM_NAME } from '../../utils/form-helpers';
import { ReactComponent as CsvIcon } from '../../assets/images/csv-svgrepo-com.svg';

type Props = {
  studyDetails: StudyDetails,
  loading: boolean,
  selectedDate: string
};

// const { Title, Text } = Typography;

const chartOptions = {
  color: '#1890FF',
  textStyle: {
    fontFamily: 'Noto Sans, sans-serif',
    color: 'rgba(0, 0, 0, 0.65)'
  },
  tooltip: {
    show: true,
    // trigger: 'axis',
    backgroundColor: '#fff',
    textStyle: {
      color: 'rgba(0, 0, 0, 0.65)'
    },
    extraCssText: 'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)'
  },
  grid: {
    height: 200,
    //   left: '10%',
    //   top: 60,
    right: 0
    //   bottom: 60,
  },
  xAxis: {
    type: 'category',
    name: 'Studies',
    nameLocation: 'start',
    // nameTextStyle: {
    //   // color: 'rgba(0, 0, 0, 0.65)',
    // },
    nameGap: 24,
    nameRotate: 90,
    // boundaryGap: false,
    data: [],
    axisTick: {
      alignWithLabel: true
    }
  },
  yAxis: {
    type: 'value',
    name: 'Volume',
    max: 30,
    nameGap: 24,
    nameTextStyle: {
      color: 'rgba(0, 0, 0, 0.85)',
      fontWeight: 500,
      fontSize: 14
    },
    splitLine: {
      lineStyle: {
        type: 'dashed'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    }
  },
  dataZoom: [
    // {
    //   show: true,
    //   realtime: true,
    // },
    {
      type: 'inside',
      realtime: true
    }
  ]
};

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function StudyDetailsCard({ studyDetails, loading, selectedDate }: Props) {
  const { modalityCount, studyCount } = studyDetails;
  const [modalityData, setModalityData] = useState([]);
  const [studyData, setStudyData] = useState([]);

  const isToday = moment().isSame(moment(selectedDate), 'day');
  const studyCountCounts = studyCount.map(i => i.studyCount);
  const studyCountDates = studyCount.map(i =>
    moment(i.date).format(isToday ? 'HH:mm' : 'DD-MM-YYYY')
  );
  useEffect(() => {
    if (studyDetails.modalityCount.length > 0)
      setModalityData(studyDetails.modalityCount);
    if (studyDetails.studyCount.length > 0)
      setStudyData(studyDetails.studyCount);
  }, [studyDetails]);

  const columns = [
    {
      title: t('dashboard.table.modality'),
      dataIndex: 'modality',
      key: 'modality',
      render: (text, record, index) => (
        <Row type='flex' align='middle' gutter={[12, 6]}>
          {text !== 'TOTAL' ? (
            <Col>
              <Avatar size='small' /* style={{ backgroundColor: '#1890FF' }} */>
                {index + 1}
              </Avatar>
            </Col>
          ) : null}
          <Col>{text}</Col>
        </Row>
      ),
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('dashboard.table.studies'),
      dataIndex: 'total',
      key: 'total',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('dashboard.table.urgent'),
      dataIndex: 'urgent',
      key: 'urgent',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('dashboard.table.routine'),
      dataIndex: 'routine',
      key: 'routine',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const renderTotalData = prop => {
    let total = 0;
    modalityCount.forEach(i => (total += i[prop]));
    return total;
  };

  const totalData = [
    {
      modality: 'TOTAL',
      total: renderTotalData('total'),
      urgent: renderTotalData('urgent'),
      routine: renderTotalData('routine')
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource:
      modalityCount && modalityCount.length > 0
        ? [...modalityCount, ...totalData]
        : [],
    rowKey: ({ id }) => id,
    // size: 'small',
    pagination: false
  };

  chartOptions.xAxis.data = studyCountDates;
  chartOptions.yAxis.max = Math.max(...studyCountCounts);

  const reactEchartsOptions = {
    ...chartOptions,
    series: [
      {
        type: 'line',
        name: 'Studies',
        symbolSize: 6,
        data: studyCountCounts
      }
    ]
  };

  // const renderTotal = () => {
  //   const renderTotalColItem = prop => {
  //     let total = 0;
  //     modalityCount.forEach(i => (total += i[prop]));
  //     return (
  //       total
  //     );
  //   };

  //   const totalData = [{
  //     modality: 'TOTAL',
  //     total: renderTotalColItem('total'),
  //     urgent: renderTotalColItem('urgent'),
  //     routine: renderTotalColItem('routine'),
  //   }]

  //   modalityCount.push(totalData)

  //   const totalTableProps = {
  //     columns: totalColumns,
  //     loading,
  //     dataSource: totalData,
  //     rowKey: ({ id }) => id,
  //     showHeader: false,
  //     pagination: false
  //   };

  //   return(
  //     <Table
  //     {...totalTableProps}
  //   />
  //   )
  // };

  const downloadCSV = (filename, arrayOfJson, arrayOfJsonSec) => {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(arrayOfJson[0]);
    const headerSec = Object.keys(arrayOfJsonSec[0]);
    const csv = arrayOfJson.map(row =>
      header
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(
      header.map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(',')
    );
    const csvSec = arrayOfJsonSec.map(row =>
      headerSec
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csvSec.unshift(
      headerSec.map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(',')
    );
    let firstColumn = ['AlemHealth Connect Study Details'];
    if (selectedDate && selectedDate.length) {
      let range = 'Date Range: ';
      if (selectedDate[0]) {
        range += moment(selectedDate[0]).format('DD MMM YYYY');
      }
      if (selectedDate[1]) {
        range += ` to ${moment(selectedDate[1]).format('DD MMM YYYY')}`;
      }
      firstColumn = [...firstColumn, range];
    }
    csv.unshift(...firstColumn);

    let tables = [...csv, ',,,', ',,,', ',,,', ',,,', ...csvSec];
    // arrayOfJsonSec
    tables = tables.join('\r\n');

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(tables)}`
    );
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {modalityData.length > 0 && (
        <Row
          justify='center'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Col>
            <Tooltip placement='left' title={t('dashboard.download_csv')}>
              <Button
                type='default'
                ghost
                style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                className='btn'
                onClick={() => {
                  if (modalityData.length > 0) {
                    downloadCSV(
                      `modality-count-study-details-${moment().format()}.csv`,
                      modalityData,
                      studyData
                    );
                  }
                }}
              >
                <CsvIcon />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
      <CardWrapper
        headStyle={{
          paddingTop: 10,
          paddingBottom: 10,
          borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
        }}
        bodyStyle={{ padding: 16 }}
        title={t('dashboard.study_details')}
        extra={<StudyDetailsCardForm />}
      >
        <Spin spinning={loading}>
          <Row gutter={[20, 12]}>
            <Col sm={24} md={24} lg={12}>
              {studyCountCounts.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <ReactEcharts
                  option={reactEchartsOptions}
                  opts={{ renderer: 'svg' }}
                />
              )}
            </Col>
            <Col sm={24} md={24} lg={12}>
              {<Table className='table-with-total' {...tableProps} />}
              {/* {renderTotal()} */}
            </Col>
          </Row>
        </Spin>
      </CardWrapper>
    </>
  );
}

const selector = formValueSelector(STUDY_DETAILS_CARD_FORM_NAME);

const mapStateToProps = (state: ReduxState) => {
  return {
    studyDetails: state.dashboard.studyDetails,
    loading: state.loading.studyDetails,
    selectedDate: selector(state, 'selectedDate')
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudyDetailsCard);
