/* eslint-disable */
import {
  ADD_GROUP_LOGO,
  REMOVE_GROUP_LOGO,
  UPLOAD_GROUP_LOGO
} from './groupActions';

export const GET_LOGIN_DATA = 'GET_LOGIN_DATA';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const LOGOUT = 'LOGOUT';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';

export const ACCOUNT_SETTINGS_REQUEST = 'ACCOUNT_SETTINGS_REQUEST';
export const ACCOUNT_SETTINGS_SUCCESS = 'ACCOUNT_SETTINGS_SUCCESS';
export const ACCOUNT_SETTINGS_FAILED = 'ACCOUNT_SETTINGS_FAILED';

export const NOTIFICATIONS_SETTINGS_REQUEST = 'NOTIFICATIONS_SETTINGS_REQUEST';
export const NOTIFICATIONS_SETTINGS_SUCCESS = 'NOTIFICATIONS_SETTINGS_SUCCESS';
export const NOTIFICATIONS_SETTINGS_FAILED = 'NOTIFICATIONS_SETTINGS_FAILED';

export const ADD_LETTERHEAD = 'ADD_LETTERHEAD';
export const REMOVE_LETTERHEAD = 'REMOVE_LETTERHEAD';
export const UPLOAD_LETTERHEAD = 'UPLOAD_LETTERHEAD';
export const RESET_LETTERHEAD = 'RESET_LETTERHEAD';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const GET_AZURE_ADDRESS_REQUEST = 'GET_AZURE_ADDRESS_REQUEST';
export const GET_AZURE_ADDRESS_SUCCESS = 'GET_AZURE_ADDRESS_SUCCESS';
export const GET_AZURE_ADDRESS_FAILED = 'GET_AZURE_ADDRESS_FAILED';

export const GET_USER_STUDY_COUNT_REQUEST = 'GET_USER_STUDY_COUNT_REQUEST';
export const GET_USER_STUDY_COUNT_SUCCESS = 'GET_USER_STUDY_COUNT_SUCCESS';
export const GET_USER_STUDY_COUNT_FAILED = 'GET_USER_STUDY_COUNT_FAILED';

export const getLoginData = () => ({ type: GET_LOGIN_DATA });
export const loginSucceed = data => ({ type: LOGIN_SUCCEED, data });
export const logout = () => ({ type: LOGOUT });
export const loginRequest = data => ({ type: LOGIN_REQUEST, data });

export const forgotPassword = data => ({
  type: FORGOT_PASSWORD_REQUEST,
  data
});

export const addNotification = data => ({ type: ADD_NOTIFICATION, data });

export const clearNotification = notificationId => ({
  type: CLEAR_NOTIFICATION,
  notificationId
});

export const readAllNotifications = () => ({ type: READ_ALL_NOTIFICATIONS });

export const updateAccountSettings = settings => ({
  type: ACCOUNT_SETTINGS_REQUEST,
  settings
});

export const updateNotificationsSettings = settings => ({
  type: NOTIFICATIONS_SETTINGS_REQUEST,
  settings
});

export const addLetterhead = (logo: string) => ({ type: ADD_LETTERHEAD, logo });
export const removeLetterhead = () => ({ type: REMOVE_LETTERHEAD });
export const uploadLetterhead = () => ({ type: UPLOAD_LETTERHEAD });
export const resetLetterhead = () => ({ type: RESET_LETTERHEAD });

export const getAzureAddress = () => ({ type: GET_AZURE_ADDRESS_REQUEST });
export const getUserStudyCountRequest = ({ startDate, endDate }) => ({
  type: GET_USER_STUDY_COUNT_REQUEST,
  payload: { startDate, endDate }
});
