const getTeleradAddress = () => {
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_SET_PRODUCTION === '1' ||
    process.env.REACT_APP_SET_PRODUCTION_TELERAD === '1'
  ) {
    return process.env.REACT_APP_TELERAD_SETTINGS_ADDRESS_PRODUCTION;
  }
  if (process.env.REACT_APP_SET_REMOTE_DEVELOP_TELERAD === '1') {
    return process.env.REACT_APP_TELERAD_SETTINGS_ADDRESS_REMOTE_DEVELOP;
  }
  return process.env.REACT_APP_TELERAD_SETTINGS_ADDRESS_DEVELOPMENT;
};

export default {
  basic: {
    siteName: 'AlemHealth Admin',
    copyright: 'AlemHealth Admin  © 2019 AlemHealth',
    logoPath: '/alemhealth_logo.png',
    namePath: '/alemhealth.png',
    environment: process.env.REACT_APP_ENV,
    localStoragePrefix: 'telerad-setting-portal-',
    localStorageCountryListKey: `telerad-setting-portal-countries`
  },
  interface: {
    fixedHeader: true // sticky primary layout header
  },
  auth0: {
    clientId: 'ipwU7FbAKnF6KO5X9Basb2BODg2hcgLj',
    domain: 'login.alem.health',
    audience: 'https://alemhealthapi.azure-api.net/',
    connection: 'TeleRadUsers',
    namespace: 'https://connect.alem.health/',
    container: 'auth0-login-container',
    primaryColor: '#04A9E3',
    logo: '/alemhealth_logo.png',
    allowAutocomplete: true
  },
  api: {
    teleradSettingsAddress: getTeleradAddress(),
    baseAddress: process.env.REACT_APP_API_BASE_ADDRESS,
    facilitiesAddress:
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_SET_PRODUCTION === '1' ||
      process.env.REACT_APP_SET_PRODUCTION_FACILITIES === '1'
        ? process.env.REACT_APP_FACILITIES_ADDRESS_PRODUCTION
        : process.env.REACT_APP_FACILITIES_ADDRESS_DEVELOPMENT,
    physiciansAddress: process.env.REACT_APP_PHYSICIANS_ADDRESS,
    referringAddress: process.env.REACT_APP_REFERRAL_ADDRESS,
    embedAddress: process.env.REACT_APP_EMBED_ADDRESS,
    subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY,
    dashboardAddress: process.env.REACT_APP_DASHBOARD_ADDRESS_PRODUCTION,
    signUpAddress: process.env.REACT_APP_SIGN_UP_PAGE,
    devicesAddress: process.env.REACT_APP_DEVICES_ADDRESS_PRODUCTION,
    signupInvite: process.env.REACT_APP_SIGNUP_INVITE_PRODUCTION
  },
  pubNub: {
    subscriptionKey: process.env.REACT_APP_PUBNUB_SUBSCRIPTION_KEY,
    pubNubTimeMultiplier: 10000000
  },
  zapierWebHook: 'https://hooks.zapier.com/hooks/catch/599828/ozjfjs2/',
  chargebee: {
    site: 'live',
    plans: {
      test: {
        alemhealthConnectFree: 'alemhealth-connect',
        alemhealthConnectPaid: 'alemhealth-connect-paid',
        alembox: 'alembox',
        alemhub: 'alemhub',
        secondOpinion: 'second-opinion'
      },
      live: {
        alemhealthConnectFree: 'alemhealth-connect-free',
        alemhealthConnectPaid: 'alemhealth-connect',
        alembox: 'alembox',
        alemhub: 'alemhub',
        secondOpinion: 'second-opinion'
      }
    }
  }
};
