// @flow
import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/charts';
import { Spin, Row, Col, Table, Empty, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import CardWrapper from '../../../../../common/CardWrapper';
import ReferringPhysiciansChartCardForm from './form/ReferringPhysiciansChartCardForm';
// import { ReduxState, StudyDetails } from '../../../../../schemas';
import {
  getStudyDetailsRequest,
  getModalitiesRequest,
  getSourceReferringPhysiciansChartAction,
  getAllPhysiciansAction,
  getAllExternalPhysiciansAction
} from '../../../../../store/actions';
import {
  selectModalityOptions,
  selectPhysicianOptions
} from '../../../../../store/selectors';
import { SOURCE_FACILITIES_CHART_FORM_NAME } from '../../../../../utils/form-helpers';

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function ReferringPhysiciansChart({
  studyDetails,
  loading,
  getStudyDetails,
  getAllPhysicians,
  getModalities,
  physiciansOptions,
  modalityOptions,
  getSourceReferringPhysiciansChart,
  getAllExternalPhysicians,
  formValues = {},
  facilities,
  chart
}) {
  const { chartData = [], tableData = [] } = chart;

  const [showMoreDetail, setShowMoreDetail] = useState(false);

  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === '/referral/referral-patterns')
      setShowMoreDetail(true);
  }, [location]);

  useEffect(() => {
    // getStudyDetails();
    getAllPhysicians();
    getAllExternalPhysicians();
    getModalities();
    getSourceReferringPhysiciansChart();
  }, []);

  // const { selectedDate, modality, facility } = formValues;

  // useEffect(() => {
  //   if (selectedDate || modality || facility) {
  //     if (selectedDate && selectedDate.length !== 0) {
  //       if (selectedDate.length === 2) {
  //         getSourceReferringPhysiciansChart();
  //       }
  //     } else {
  //       getSourceReferringPhysiciansChart();
  //     }
  //   }
  // }, [selectedDate, modality, facility]);

  const annotations = [];
  chartData.forEach((values, k) => {
    const value = chartData
      .filter(_data => _data.date === values.date)
      .reduce((a, b) => a + b.value, 0);
    annotations.push({
      type: 'text',
      position: [values.date, value],
      content: `${value}`,
      style: {
        textAlign: 'center',
        fontSize: 12,
        fill: '#adafb2'
      },
      offsetY: -10
    });
  });

  const config = {
    data: chartData,
    isStack: true,
    xField: 'date',
    yField: 'value',
    columnWidthRatio: 0.3,
    seriesField: 'referrerName',
    color: ['#15A9E1', '#075087', '#73D8FF'],
    label: {
      position: 'middle',
      style: {
        fontSize: 8
      }
    },
    padding: 'auto',
    appendPadding: 20,
    annotations
  };

  const facilitiesMapping = {};
  facilities.forEach(item => {
    facilitiesMapping[item.guid] = item.name;
  });

  const columns = [
    {
      title: t('referral_patterns.table.top_referrers'),
      dataIndex: 'referrer',
      key: 'referrer',
      render: (text, record, index) =>
        text === 'TOTAL' ? <span>TOTAL</span> : <span>{text}</span>
    },
    {
      title: t('referral_patterns.table.studies'),
      dataIndex: 'study',
      key: 'study',
      render: (text, record, index) => <span>{text}</span>,
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('referral_patterns.table.urgent'),
      dataIndex: 'urgent',
      key: 'urgent',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  // const renderTotalData = prop => {
  //   let total = 0;
  //   tableData.forEach(i => (total += i[prop]));
  //   return total;
  // };

  // const totalData = [
  //   {
  //     facilityGuid: 'TOTAL',
  //     modality: '',
  //     urgent: renderTotalData('urgent'),
  //     routine: renderTotalData('routine')
  //   }
  // ];

  const tableProps = {
    columns,
    loading,
    dataSource: tableData && tableData.length > 0 ? [...tableData] : [],
    rowKey: ({ referrer }) => referrer,
    pagination: false,
    className: 'chart-table'
  };

  return (
    <div className='facility-chart'>
      <CardWrapper
        headStyle={{
          paddingTop: 10,
          paddingBottom: 10,
          borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
        }}
        bodyStyle={{ padding: 16 }}
        title={t('side_bar.Referring Physicians')}
        extra={
          <ReferringPhysiciansChartCardForm
            physiciansOptions={physiciansOptions}
            modalityOptions={modalityOptions}
          />
        }
      >
        <Spin spinning={loading}>
          <Row gutter={[20, 12]}>
            <Col sm={24} md={24} lg={16}>
              {chartData && chartData.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <Column {...config} />
              )}
            </Col>
            <Col sm={24} md={24} lg={8}>
              <Table {...tableProps} />
              {showMoreDetail && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '-16px'
                  }}
                >
                  <Button type='link' href='/referral/referring-physicians'>
                    {t('referral_patterns.table.more_details')}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Spin>
      </CardWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    studyDetails: state.dashboard.studyDetails,
    chart: state.referringPhysicians.chart,
    facilities: state.facilities.internalArray,
    loading: state.loading.getSourceReferringPhysiciansChart,
    physiciansOptions: selectPhysicianOptions(state),
    modalityOptions: selectModalityOptions(state),
    formValues: getFormValues(SOURCE_FACILITIES_CHART_FORM_NAME)(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStudyDetails: getStudyDetailsRequest,
      getAllPhysicians: getAllPhysiciansAction,
      getModalities: getModalitiesRequest,
      getSourceReferringPhysiciansChart: getSourceReferringPhysiciansChartAction,
      getAllExternalPhysicians: getAllExternalPhysiciansAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferringPhysiciansChart);
