/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';

import { Typography, Button } from 'antd';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../../schemas';

import IndexBranding from './second-opinion/branding';

import '../index.less';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onSubmit: Function,
  onBack: Function,
  onSkip: Function,
  submitForm: Function
};

const { Title, Text } = Typography;

const Branding = ({
  data,
  activeId,
  change,
  onSubmit,
  onBack,
  onSkip,
  submitForm
}: Props) => {
  return (
    <>
      <Title
        className='onboarding-action-title'
        style={{ display: 'flex', justifyContent: 'space-between' }}
        level={3}
      >
        Set Your Branding
      </Title>
      <Text className='onboarding-action-subtitle'>
        Upload your logo, mailing address, and even customise the colour scheme
        of your upload page to make it yours.
      </Text>
      <IndexBranding onSubmit={onSubmit} />
      <div className='onboarding-action-buttons'>
        <Button type='link' size='large' onClick={onBack}>
          Go Back
        </Button>
        <Button
          type='primary'
          size='large'
          onClick={() => submitForm('brandingForm')}
        >
          Continue
        </Button>
      </div>
      <div className='onboarding-action-buttons'>
        <Button className='ant-btn-line' size='large' onClick={onSkip}>
          Skip for Now
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitForm: submit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Branding);
