/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';
import { Typography, Tabs, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { ReduxState } from '../../../schemas';
import AlemHubLatest from '../../../static/images/AlemHubLatest.png';
import AlemBoxLatest from '../../../static/images/AlemBoxLatest.png';

import '../index.less';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onSubmit: Function,
  onBack: Function,
  onSkip: Function
};

const { Title, Text } = Typography;

const OrderHardware = ({
  data,
  activeId,
  change,
  onSubmit,
  onBack,
  onSkip
}: Props) => {
  const [activeKey, setActiveKey] = React.useState('1');
  const { TabPane } = Tabs;

  return (
    <>
      <Title className='onboarding-action-title' level={3}>
        Order AlemHealth Hardware
      </Title>
      <Text className='onboarding-action-subtitle'>
        Our award winning RIS/PACS hardware products help cater to facilities of
        all sizes in the most challenging environments.
      </Text>
      <Row type='flex' justify='center'>
        <Col>
          <Tabs defaultActiveKey='1' onTabClick={value => setActiveKey(value)}>
            <TabPane tab='AlemBox' key='1'></TabPane>
            <TabPane tab='AlemHub' key='2'></TabPane>
          </Tabs>
        </Col>
      </Row>
      {activeKey === '1' ? (
        <Row>
          <Col xs={24} md={10} align='middle'>
            <img
              src={AlemBoxLatest}
              alt='AlemHub'
              style={{ maxWidth: '100%', height: '200px' }}
            />
          </Col>
          <Col xs={24} md={14}>
            <Title level={2} style={{ color: '#15A9E1' }}>
              AlemBox
            </Title>
            <Text style={{ fontSize: 16 }}>
              Custom hardware designed for easy, high volume uploads directly
              from your imaging equipment such as CT, MRI, X-Ray, and
              Mammography to your AlemHealth Connect storage.
            </Text>
            <Row
              type='flex'
              justify='space-between'
              style={{ marginTop: 20 }}
              alignItems='center'
            >
              <Col>
                <div style={{ fontSize: 22, fontWeight: 'bold' }}>$79</div>
              </Col>
              <Col>
                <a
                  href='https://alemhealth.com/providers'
                  style={{
                    fontSize: 15,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  target='_blank'
                >
                  <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.9862 1.88894L7.8808 2.37241C7.72719 2.39019 7.66413 2.57614 7.77246 2.68448L9.00135 3.91336L3.92411 8.9906C3.85297 9.06174 3.85297 9.17816 3.92411 9.24931L4.82961 10.1548C4.90075 10.2259 5.01718 10.226 5.08832 10.1548L10.1656 5.07757L11.3944 6.30645C11.5028 6.41479 11.6903 6.35011 11.7065 6.19812L12.19 2.09267C12.1933 2.06511 12.1904 2.03715 12.1813 2.0109C12.1723 1.98465 12.1574 1.9608 12.1377 1.94117C12.1181 1.92154 12.0943 1.90663 12.068 1.89758C12.0418 1.88853 12.0138 1.88557 11.9862 1.88894Z'
                      fill='#15A9E1'
                    />
                    <path
                      d='M12.7666 7.62086H11.8639C11.793 7.62086 11.7349 7.67889 11.7349 7.74982V12.908H1.1606V2.33369H6.31881C6.38974 2.33369 6.44777 2.27566 6.44777 2.20474V1.30205C6.44777 1.23113 6.38974 1.1731 6.31881 1.1731H0.515822C0.230508 1.1731 0 1.4036 0 1.68892V13.5528C0 13.8381 0.230508 14.0686 0.515822 14.0686H12.3797C12.665 14.0686 12.8955 13.8381 12.8955 13.5528V7.74982C12.8955 7.67889 12.8375 7.62086 12.7666 7.62086Z'
                      fill='#15A9E1'
                    />
                  </svg>
                  Learn More
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={24} md={10} align='middle'>
            <img
              src={AlemHubLatest}
              alt='AlemHub'
              style={{ maxWidth: '100%', height: '200px' }}
            />
          </Col>
          <Col xs={24} md={14}>
            <Title level={2} style={{ color: '#15A9E1' }}>
              AlemHub
            </Title>
            <Text style={{ fontSize: 16 }}>
              Plug-and Play Diagnostic Viewing server for radiology reporting of
              images captured on-site and studies shared over the AlemHealth
              network. All your images in one place, no limit on the number of
              radiologists.
            </Text>
            <Row
              type='flex'
              justify='space-between'
              style={{ marginTop: 20 }}
              alignItems='center'
            >
              <Col>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>$299</div>
              </Col>
              <Col>
                <a
                  href='https://alemhealth.com/providers'
                  style={{
                    fontSize: 15,
                    display: 'flex',
                    alignItems: 'centerIs '
                  }}
                  target='_blank'
                >
                  <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.9862 1.88894L7.8808 2.37241C7.72719 2.39019 7.66413 2.57614 7.77246 2.68448L9.00135 3.91336L3.92411 8.9906C3.85297 9.06174 3.85297 9.17816 3.92411 9.24931L4.82961 10.1548C4.90075 10.2259 5.01718 10.226 5.08832 10.1548L10.1656 5.07757L11.3944 6.30645C11.5028 6.41479 11.6903 6.35011 11.7065 6.19812L12.19 2.09267C12.1933 2.06511 12.1904 2.03715 12.1813 2.0109C12.1723 1.98465 12.1574 1.9608 12.1377 1.94117C12.1181 1.92154 12.0943 1.90663 12.068 1.89758C12.0418 1.88853 12.0138 1.88557 11.9862 1.88894Z'
                      fill='#15A9E1'
                    />
                    <path
                      d='M12.7666 7.62086H11.8639C11.793 7.62086 11.7349 7.67889 11.7349 7.74982V12.908H1.1606V2.33369H6.31881C6.38974 2.33369 6.44777 2.27566 6.44777 2.20474V1.30205C6.44777 1.23113 6.38974 1.1731 6.31881 1.1731H0.515822C0.230508 1.1731 0 1.4036 0 1.68892V13.5528C0 13.8381 0.230508 14.0686 0.515822 14.0686H12.3797C12.665 14.0686 12.8955 13.8381 12.8955 13.5528V7.74982C12.8955 7.67889 12.8375 7.62086 12.7666 7.62086Z'
                      fill='#15A9E1'
                    />
                  </svg>
                  Learn More
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <div className='onboarding-action-buttons'>
        <Button type='link' size='large' onClick={onBack}>
          Go Back
        </Button>

        <Button
          type='primary'
          size='large'
          onClick={() => onSubmit(activeKey === 1 ? 'Alembox' : 'AlemHub')}
        >
          Continue
        </Button>
      </div>
      <div className='onboarding-action-buttons'>
        <Button className='ant-btn-line' size='large' onClick={onSkip}>
          Skip for Now
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHardware);
