import type { User } from '../../schemas';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED';

export const REVOKE_ADMIN_RIGHTS_REQUEST = 'REVOKE_ADMIN_RIGHTS_REQUEST';
export const REVOKE_ADMIN_RIGHTS_SUCCESS = 'REVOKE_ADMIN_RIGHTS_SUCCESS';
export const REVOKE_ADMIN_RIGHTS_FAILED = 'REVOKE_ADMIN_RIGHTS_FAILED';

export const UPDATE_LAST_USERS_SEARCH = 'UPDATE_LAST_USERS_SEARCH';

export const getUsersAction = (
  perPage: number,
  page: number,
  sort: string,
  query: string
) => ({ type: GET_USERS_REQUEST, perPage, page, sort, query });

export const getAllUsersAction = () => ({ type: GET_ALL_USERS_REQUEST });

export const getUserByIdAction = (userId: string) => ({
  type: GET_USER_BY_ID_REQUEST,
  userId
});

export const addUserAction = (user: User) => ({ type: ADD_USER_REQUEST, user });

export const editUserAction = (userUpdate: User, userId: string) => ({
  type: EDIT_USER_REQUEST,
  userUpdate,
  userId
});

export const deleteUserAction = (userId: string) => ({
  type: DELETE_USER_REQUEST,
  userId
});

export const resetUserPasswordAction = email => ({
  type: RESET_USER_PASSWORD_REQUEST,
  email
});

export const revokeAdminRightsAction = (oldAdminId, newAdminId) => ({
  type: REVOKE_ADMIN_RIGHTS_REQUEST,
  oldAdminId,
  newAdminId
});

export type RevokeAdminRightsAction = {
  type: string,
  oldAdminId: string,
  newAdminId: string
};
