import React from 'react';
import { reduxForm } from 'redux-form';
import { Button, Col, Row } from 'antd';

import { connect } from 'react-redux';
import { t } from 'i18next';
import validation from './validation';
import BaseProviderForm from './BaseProviderForm';

import type { Country, ReduxState } from '../../../../schemas';

const ADD_PROVIDER_FORM_NAME = 'addProviderForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  countryList: Array<Country>,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean
};

export default connect((state: ReduxState) => ({
  initialValues: state.initialForm.addProvider,
  countryList: state.countryList
}))(
  reduxForm({
    form: ADD_PROVIDER_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      countryList
    }: Props) => (
      <BaseProviderForm
        title={t('manage_radiologists.new_provider')}
        okText='Add'
        visible={visible}
        onCancel={onCancel}
        countryList={countryList}
      >
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button onClick={onCancel}>{t('button.close')}</Button>
            <Button
              disabled={pristine || submitting}
              type='primary'
              onClick={handleSubmit}
              loading={loading}
            >
              {t('button.add')}
            </Button>
          </Col>
        </Row>
      </BaseProviderForm>
    )
  )
);
