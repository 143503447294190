import * as React from 'react';
import { connect } from 'react-redux';

import {
  deleteFacilityAction,
  getInternalFacilitiesAction,
  getFacilityByIdAction
} from '../../../../store/actions';
import Table from '../../../../components/Table';
import type { ReduxState, Facility } from '../../../../schemas';
import type { FacilityIdAction } from '../../../../store/actions';
import type { EmptyAction } from '../../../../store/actions/types';

import TableMenu from './TableMenu';
import getColumns from './columns';

import './Table.less';

type Props = {
  data: Array<Facility>,
  loading: boolean,
  modifications: number,
  getFacilities: () => EmptyAction,
  getFacility: string => FacilityIdAction,
  deleteFacility: string => FacilityIdAction
};

export default connect(
  (state: ReduxState) => ({
    data: state.facilities.internalArray,
    loading: state.loading.facilities,
    modifications: state.facilities.modifications
  }),
  {
    getFacilities: getInternalFacilitiesAction,
    getFacility: getFacilityByIdAction,
    deleteFacility: deleteFacilityAction
  }
)(
  ({
    data,
    loading,
    modifications,
    getFacilities,
    getFacility,
    deleteAction
  }: Props) => {
    const columns = getColumns((text, record) => (
      <TableMenu
        record={record}
        getAction={getFacility}
        deleteAction={deleteAction}
      />
    ));

    const rowKey = record => record.guid;

    return (
      <Table
        columns={columns}
        data={data}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getFacilities}
      />
    );
  }
);
