// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import Table from '../../components/Table';
import {
  getInvitationsListRequest,
  postResendInvitationRequest,
  deleteRevokeInvitationRequest
} from '../../store/actions';

const TableInvites = ({
  total,
  loading,
  invitations,
  modifications,
  resendInvitation,
  getInvitationsList,
  deleteRevokeInvitation
}) => {
  useEffect(() => {
    getInvitationsList();
  }, []);

  const actionHandle = (invitationGuid, action) => {
    if (action === 'resend') {
      resendInvitation(invitationGuid);
    } else if (action === 'revoke') {
      deleteRevokeInvitation(invitationGuid);
    }
  };

  const columns = [
    {
      title: t('dashboard.table.name'),
      dataIndex: 'name',
      sorter: (a, b) =>
        (a.name ? a.name : '')
          .toString()
          .toLowerCase()
          .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
      columnSearch: true,
      render: (text, record) => text
    },
    {
      title: t('dashboard.table.action'),
      key: 'invitation_guid',
      render: (text, record) => (
        <Row type='flex' gutter={[10]}>
          <Col>
            <Button
              onClick={() => actionHandle(text.invitation_guid, 'resend')}
              type='primary'
            >
              {t('dashboard.table.resend_invite')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => actionHandle(text.invitation_guid, 'revoke')}
              type='default'
            >
              {t('dashboard.table.revoke_invitation')}
            </Button>
          </Col>
        </Row>
      )
    }
  ];

  const rowKey = invite => invite.invitation_guid;
  return (
    <div className='invite-table'>
      <Table
        columns={columns}
        total={total}
        data={invitations}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={() => null}
      />
    </div>
  );
};

export default connect(
  state => ({
    invitations: state.groupInvitations
  }),
  {
    getInvitationsList: getInvitationsListRequest,
    resendInvitation: postResendInvitationRequest,
    deleteRevokeInvitation: deleteRevokeInvitationRequest
  }
)(TableInvites);
