// @flow
import React, { useEffect } from 'react';
import { Area } from '@ant-design/charts';
import { Spin, Row, Col, Table, Empty } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { t } from 'i18next';
import CardWrapper from '../../../../common/CardWrapper';
import SourceFacilitiesChartCardForm from './form/SourceFacilitiesChartCardForm';
import { ReduxState, StudyDetails } from '../../../../schemas';
import {
  getStudyDetailsRequest,
  getFacilitiesRequest,
  getModalitiesRequest,
  getSourceFacilitiesChartAction
} from '../../../../store/actions';
import {
  selectFacilityOptions,
  selectModalityOptions
} from '../../../../store/selectors';
import { SOURCE_FACILITIES_CHART_FORM_NAME } from '../../../../utils/form-helpers';

type Props = {
  studyDetails: StudyDetails,
  loading: boolean,
  facilities: Array<Object>,
  getStudyDetails: void => {},
  getFacilities: void => {},
  getModalities: void => {},
  facilityOptions: any,
  modalityOptions: any,
  getSourceFacilitiesChart: void => {},
  chart: Object,
  formValues: any
};

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function SourceFacilitiesChart({
  studyDetails,
  loading,
  getStudyDetails,
  getFacilities,
  getModalities,
  facilityOptions,
  modalityOptions,
  getSourceFacilitiesChart,
  formValues = {},
  facilities,
  chart
}: Props) {
  const { chartData = [], tableData = [] } = chart;

  useEffect(() => {
    // getStudyDetails();
    getFacilities();
    getModalities();
    getSourceFacilitiesChart();
  }, []);

  const { selectedDate, modality, facility } = formValues;

  useEffect(() => {
    if (selectedDate || modality || facility) {
      if (selectedDate && selectedDate.length !== 0) {
        if (selectedDate.length === 2) {
          getSourceFacilitiesChart();
        }
      } else {
        getSourceFacilitiesChart();
      }
    }
  }, [selectedDate, modality, facility]);

  const config = {
    data: chartData,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    color: [
      '#6897a7',
      '#8bc0d6',
      '#60d7a7',
      '#dedede',
      '#fedca9',
      '#fab36f',
      '#d96d6f'
    ],
    legend: { position: 'top' }
  };

  const facilitiesMapping = {};
  facilities.forEach(item => {
    facilitiesMapping[item.guid] = item.name;
  });

  const columns = [
    {
      title: t('my_facilities.source_facilities'),
      dataIndex: 'facilityGuid',
      key: 'facilityGuid',
      render: (text, record, index) =>
        text === 'TOTAL' ? (
          <span>TOTAL</span>
        ) : (
          <span>{facilitiesMapping[text]}</span>
        )
    },
    {
      title: t('my_facilities.modality'),
      dataIndex: 'modality',
      key: 'modality',
      render: (text, record, index) => <span>{text}</span>,
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('my_facilities.urgent'),
      dataIndex: 'urgent',
      key: 'urgent',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('my_facilities.routine'),
      dataIndex: 'routine',
      key: 'routine',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const renderTotalData = prop => {
    let total = 0;
    tableData.forEach(i => (total += i[prop]));
    return total;
  };

  const totalData = [
    {
      facilityGuid: 'TOTAL',
      modality: '',
      urgent: renderTotalData('urgent'),
      routine: renderTotalData('routine')
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource:
      tableData && tableData.length > 0 ? [...tableData, ...totalData] : [],
    rowKey: ({ id }) => id,
    pagination: false
  };

  return (
    <div className='facility-chart'>
      <CardWrapper
        headStyle={{
          paddingTop: 10,
          paddingBottom: 10,
          borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
        }}
        bodyStyle={{ padding: 16 }}
        title={t('my_facilities.source_facilities')}
        extra={
          <SourceFacilitiesChartCardForm
            facilityOptions={facilityOptions}
            modalityOptions={modalityOptions}
            getSourceFacilitiesChart={() => getSourceFacilitiesChart()}
          />
        }
      >
        <Spin spinning={loading}>
          <Row gutter={[20, 12]}>
            <Col sm={24} md={24} lg={12}>
              {chartData && chartData.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <Area {...config} />
              )}
            </Col>
            <Col sm={24} md={24} lg={12}>
              <Table className='table-with-total' {...tableProps} />
            </Col>
          </Row>
        </Spin>
      </CardWrapper>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    studyDetails: state.dashboard.studyDetails,
    chart: state.facilities.chart,
    facilities: state.facilities.internalArray,
    loading: state.loading.getSourceFacilitiesChart,
    facilityOptions: selectFacilityOptions(state),
    modalityOptions: selectModalityOptions(state),
    formValues: getFormValues(SOURCE_FACILITIES_CHART_FORM_NAME)(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStudyDetails: getStudyDetailsRequest,
      getFacilities: getFacilitiesRequest,
      getModalities: getModalitiesRequest,
      getSourceFacilitiesChart: getSourceFacilitiesChartAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceFacilitiesChart);
