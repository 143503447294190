/**
 * Api endpoints to retrieve the information and change the users of the affiliated group of the calling user. Every
 * method requires admin privileges, as only admins are allowed to see the data of other users of the group.
 */

import AuthService from '../index';
import Logger from '../../utils/logger';
import querier from './querier';
import { jsonToUser, userToJson } from '../transformers';

import type {
  User,
  UserProfile,
  MultipleUserResponse,
  ApiService
} from '../../schemas';

const POST_SIGNUP_ROOT_URL_INVITATION_LIST = 'signup-invite-list';
const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/users';

/**
 * Get the users of the user affiliated group. Requires admin privileges.
 * @param profile: user profile
 * @param perPage: number of results to get by page
 * @param page: page of results
 * @param sort: column to order the returned results.
 * @param query: a query string that represents additional queries to pass to limit user results. If multiple queries
 * shall be passed, they must be separated by a plus ("+") sign. Each query follows the lucene query format (key:value)
 * For example: `name:Mark+email:mark@radiologist.com`
 * @returns {never|Promise<Response>} A promise with an http response containing a list of users
 */
export const getUsersApiCall = async (
  profile: UserProfile,
  perPage: number,
  page: number,
  sort: string,
  query: string
) => {
  let route = BASE_ROUTE;
  if (perPage > 0 || page > 0 || sort.length > 0 || query.length > 0) {
    route += querier(perPage, page, sort, query);
  }
  Logger.log('GET users api call with route: ', route);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, route, {})
    .then((response: MultipleUserResponse) => {
      Logger.log('GET Users api response: ', response);
      return {
        array: response.users.map(u => jsonToUser(u)),
        total: response.total
      };
    });
};

export const getAllUsersApiCall = async (profile: UserProfile) => {
  const route = BASE_ROUTE;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, route, {})
    .then((response: MultipleUserResponse) => {
      Logger.log('GET Users api response: ', response);
      return {
        array: response.users.map(u => jsonToUser(u)),
        total: response.total
      };
    });
};
/**
 * Retrieves a user by id. Requires admin privileges.
 * @param profile: user profile
 * @param id: id of the user to retrieve
 * @returns {never|Promise<*>}: the radiologist with the provided id
 */
export const getUserByIdApiCall = async (profile: UserProfile, id: string) => {
  Logger.log('GET User by id api call with id: ', id);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then(u => {
      Logger.log('GET User by id api response: ', u);
      return jsonToUser(u);
    });
};

/**
 * Creates a user belonging to the user affiliated group. Requires admin privileges.
 * @param profile: user profile
 * @param user the user data
 * @returns {never|Promise<*>} Returns the created user
 */
export const createUserApiCall = async (profile: UserProfile, user: User) => {
  Logger.log('POST user api call with user ', user);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(
      POST_SIGNUP_ROOT_URL_INVITATION_LIST,
      `/organization/${user.organization_guid}/invitations/`,
      {
        method: 'POST',
        body: userToJson(user)
      }
    )
    .then(r => {
      Logger.log('POST user api call response: ', r);
      return r;
    });
};

/**
 * Updates an user with the data passed in the body.
 * @param profile: user profile
 * @param id: id of the user to update
 * @param userUpdate the data to update
 * @returns {never|Promise<*>} Returns the updated radiologist.
 */
export const updateUserApiCall = async (
  profile: UserProfile,
  id: string,
  userUpdate
) => {
  Logger.log('PATCH user api call with update: ', userUpdate);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
      method: 'PATCH',
      body: userToJson(userUpdate)
    })
    .then(r => {
      Logger.log('PATCH user api call response: ', r);
      return r;
    });
};

/**
 * Deletes a user
 * @param profile: user profile
 * @param id: id of the user to delete
 * @returns {never|Promise<*>} A {message} with a confirmation response.
 */
export const deleteUserApiCall = async (profile: UserProfile, id: string) => {
  Logger.log('DELETE user api call');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, { method: 'DELETE' })
    .then(r => {
      Logger.log('DELETE user api response ', r);
      return r;
    });
};

/**
 * Only method that a non admin can call. To be called, the calling user email and the passed email have to match.
 * If this is not the case, the calling user must be an admin.
 * @param profile: user profile
 * @param email: email of the user to reset the password
 * @returns {never|Promise<*>} A {message} with a confirmation response.
 */
export const resetPasswordApiCall = async (
  profile: UserProfile,
  email: string
) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(
    API_SERVICE,
    `${BASE_ROUTE}/reset-password`,
    {
      method: 'POST',
      body: JSON.stringify({ email })
    }
  );
};

export const changeMainAdminApiCall = async (
  profile: UserProfile,
  oldId: string,
  newId: string
) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(
    API_SERVICE,
    `${BASE_ROUTE}/change-main-admin`,
    {
      method: 'POST',
      body: JSON.stringify({ old: oldId, new: newId })
    }
  );
};
