import * as React from 'react';
import { Tooltip } from 'antd';

type Props = {
  text: string,
  active: boolean,
  children: React.ReactNode
};

export default ({ text, active, children }: Props) =>
  active ? <Tooltip title={text}>{children}</Tooltip> : children;
