// @flow
import React from 'react';
import { Spin, Row, Col, Table } from 'antd';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CardWrapper from '../../common/CardWrapper';
import { ReduxState, StudyDetails } from '../../schemas';
// import { selectStudyDetails } from '../../store/selectors';
import { PROCEDURE_DETAILS_CARD_FORM_NAME } from '../../utils/form-helpers';
import ProcedureDetailsCardForm from './form/ProcedureDetailsCardForm';
import ProcedureDetailsSecondCardForm from './form/ProcedureDetailsSecondCardForm';

type Props = {
  studyDetails: StudyDetails,
  loading: boolean,
  selectedDate: string
};

const dummyData = [
  {
    key: 1,
    device: 'MR Nohil',
    modality: 'MR',
    facility: 'Facility Name 01',
    utilisation: [
      { value: 240, color: '#6395F9' },
      { value: 60, color: '#657798' },
      { value: 240, color: '#6395F9' },
      { value: 120, color: '#657798' },
      { value: 240, color: '#6395F9' },
      { value: 60, color: '#657798' },
      { value: 240, color: '#6395F9' }
    ],
    timeInUse: '16:00:00'
  },
  {
    key: 2,
    device: 'MR Nohil',
    modality: 'MR',
    facility: 'Facility Name 02',
    utilisation: [
      { value: 240, color: '#6395F9' },
      { value: 60, color: '#657798' },
      { value: 240, color: '#6395F9' },
      { value: 120, color: '#657798' },
      { value: 240, color: '#6395F9' },
      { value: 60, color: '#657798' },
      { value: 240, color: '#6395F9' }
    ],
    timeInUse: '16:00:00'
  }
];

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function ProcedureDetailCard({ studyDetails, loading, selectedDate }: Props) {
  const columns = [
    {
      title: 'Device Name',
      dataIndex: 'device',
      key: 'device',
      render: (text, record, index) => (
        <Row type='flex' align='middle' gutter={[12, 6]}>
          <Col>{text}</Col>
        </Row>
      ),
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Modality',
      dataIndex: 'modality',
      key: 'modality',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Daily Utilisation',
      dataIndex: 'utilisation',
      key: 'utilisation',
      render: (text, record) => {
        return <HSBar data={record.utilisation} />;
      },
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Time In Use',
      dataIndex: 'timeInUse',
      key: 'timeInUse',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource: dummyData,
    rowKey: ({ id }) => id,
    // size: 'small',
    pagination: false
  };

  return (
    <CardWrapper
      headStyle={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
      }}
      bodyStyle={{ padding: 16 }}
      title='Procedure Detail'
      extra={<ProcedureDetailsCardForm />}
    >
      <Row gutter={[20, 12]}>
        <Col sm={24} md={24} lg={24}>
          <ProcedureDetailsSecondCardForm />
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Row gutter={[20, 12]}>
          <Col sm={24} md={24} lg={24}>
            <Table {...tableProps} />
          </Col>
        </Row>
      </Spin>
    </CardWrapper>
  );
}

const selector = formValueSelector(PROCEDURE_DETAILS_CARD_FORM_NAME);

const mapStateToProps = (state: ReduxState) => {
  return {
    studyDetails: state.dashboard.studyDetails,
    loading: state.loading.studyDetails,
    selectedDate: selector(state, 'dateFilter')
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureDetailCard);
