// @flow
import React, { Component } from 'react';
import { Button, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { t } from 'i18next';
import AddUserForm from './forms/AddUserForm';

import {
  addUserAction,
  getInternalFacilitiesAction,
  loadAddUserForm
} from '../../store/actions';
import { editUserValuesToUser } from '../../api/transformers';

import type { Facility, ReduxState, User } from '../../schemas';

type Props = {
  facilities: Array<Facility>,
  loading: boolean,
  loadingFacilities: boolean,
  affiliatedGroup: string,
  modifications: number,
  createAction: User => void,
  loadFacilities: () => void
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.createUserForm,
    loading: state.loading.createUser,
    loadingFacilities: state.loading.facilities,
    modifications: state.users.modifications,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    facilities: state.facilities.internalArray
  }),
  {
    createAction: addUserAction,
    loadForm: loadAddUserForm,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class AddUser extends Component<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications,
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount() {
      const { loadFacilities } = this.props;
      loadFacilities();
    }

    showModal = () => {
      this.setState({ visible: true });
    };

    handleCancel = () => {
      this.setState({ visible: false });
    };

    handleCreate = async values => {
      const { createAction, affiliatedGroup, facilities } = this.props;
      const data = editUserValuesToUser(values, affiliatedGroup, facilities);
      createAction(data);
    };

    render() {
      const { loading, loadingFacilities } = this.props;
      const { visible } = this.state;
      return (
        <div>
          <Button type='primary' onClick={this.showModal}>
            {t('button.add_new_user')}
          </Button>
          {visible ? ( // We have to use this trick to force form destruction
            loadingFacilities ? (
              <Modal
                visible={visible}
                title={t('user_administration.new_internal_radiologist')}
                okText='Add'
                footer={null}
                onCancel={this.handleCancel}
                wrapClassName='lateral-modal'
              >
                <div style={{ textAlign: 'center' }}>
                  <Spin size='large' />
                </div>
              </Modal>
            ) : (
              <AddUserForm
                visible={visible}
                loading={loading}
                onCancel={this.handleCancel}
                onSubmit={this.handleCreate}
              />
            )
          ) : null}
        </div>
      );
    }
  }
);
