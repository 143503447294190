// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import AddDeviceForm from './forms/AddDeviceForm';

import {
  addDeviceAction,
  getInternalFacilitiesAction
} from '../../../store/actions';

import type { ReduxState, Device } from '../../../schemas';

type Props = {
  loading: boolean,
  modifications: number,
  createAction: Device => void,
  loadFacilities: () => void
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    loading: state.loading.createDevice,
    modifications: state.devices.modifications,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup
  }),
  {
    createAction: addDeviceAction,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class AddDevice extends Component<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications,
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount(): * {
      const { loadFacilities } = this.props;
      loadFacilities();
    }

    showModal = () => {
      this.setState({ visible: true });
    };

    handleCancel = () => {
      this.setState({ visible: false });
    };

    handleCreate = async values => {
      const { createAction } = this.props;
      createAction(values);
    };

    render() {
      const { loading } = this.props;
      const { visible } = this.state;
      return (
        <div>
          <div style={{ marginBottom: '24px' }}>
            <Button type='primary' onClick={this.showModal}>
              Add New User
            </Button>
          </div>
          {visible ? (
            <AddDeviceForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
            />
          ) : null}
        </div>
      );
    }
  }
);
