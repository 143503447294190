// @flow
import React from 'react';
import { Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from 'i18next';
import userValidation from './userValidation';
import BaseUserForm from './BaseUserForm';
import ActionLink from '../../../components/ActionLink';

import type { User } from '../../../schemas';

const EDIT_USER_FORM_NAME = 'editUserForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Called when the delete button is pushed
  onDelete: () => void,
  // User to edit, used to extract its role and if it's main
  user: User,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean,
  isActiveUser: boolean
};

/**
 * Adapts the BaseUserForm to edit a user. It's connected to reduxForm.
 */
export default connect(
  null,
  {}
)(
  reduxForm({
    form: EDIT_USER_FORM_NAME,
    validate: userValidation
  })((props: Props) => {
    const {
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      onDelete,
      user,
      isActiveUser
    } = props;
    const isMain = user.appMetadata.main === '1';
    return (
      <BaseUserForm
        title={t('dashboard.edit_user')}
        okText='Update'
        visible={visible}
        loading={loading}
        onCancel={onCancel}
        isMain={isMain}
        role={user.userMetadata.userType}
        isActiveUser={isActiveUser}
        userId={user.id}
        selectedFacilities={user.appMetadata.approvedFacilities}
      >
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={12} style={{ textAlign: 'left' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              disabled={pristine || submitting}
              type='primary'
              onClick={handleSubmit}
              loading={loading}
            >
              {t('user_administration.save_changes')}
            </Button>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <ActionLink
              click={onDelete}
              text={t('user_administration.field.delete_user')}
              disabled={isMain}
            />
          </Col>
        </Row>
      </BaseUserForm>
    );
  })
);
