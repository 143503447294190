/* eslint-disable consistent-return */
/* eslint-disable no-console */
import {
  BlockBlobURL,
  Aborter,
  uploadBrowserDataToBlockBlob
} from '@azure/storage-blob';

const AZURE_STORAGE_ACCOUNT_NAME = 'alemhealth';
export const AZURE_CONTAINER = 'ahcontainer';
export const AZURE_URL_PREFIX_FOR_STORAGE =
  'https://alemhealth.blob.core.windows.net';
export const AZURE_DELEGATION_API_URL =
  'https://alemhealthapi.azure-api.net/auth0-delegation/api/container_sas';
export const AZURE_DELEGATION_API_SUBSCRIPTION_KEY =
  'a2de78118b16429d82da4c666376f149';

const fileToDataURL = file => {
  const reader = new window.FileReader();
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result);
    };
    reader.readAsDataURL(file);
  });
};

const imageToBlob = src => {
  const img = new window.Image();
  return new Promise(resolve => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext('2d').drawImage(img, 0, 0);
      canvas.toBlob(resolve, 'image/png');
    };
    img.src = src;
  });
};

export const transformFile = async file => {
  const fileType = file.type;
  const dataURL = await fileToDataURL(file);
  const blob = await imageToBlob(dataURL);
  if (
    fileType.indexOf('image') === -1 ||
    fileType === 'image/png' ||
    fileType === 'image/svg+xml'
  ) {
    if (fileType === 'image/svg+xml') {
      blob.name = file.name.replace(/\.[^.]+$/g, '.svg');
    } else {
      blob.name = file.name.replace(/\.[^.]+$/g, '.png');
    }
    blob.name = blob.name.replace(/ /g, '_');

    return blob;
  }

  blob.name = file.name.replace(/\.[^.]+$/g, '.png');
  blob.name = blob.name.replace(/ /g, '_');

  return blob;
};

export const getContainerURL = async ({ azureBlobSAS }) => {
  const accountName = AZURE_STORAGE_ACCOUNT_NAME;
  const sasString = azureBlobSAS;
  const containerName = AZURE_CONTAINER;
  const containerURL = new window.azblob.ContainerURL(
    `https://${accountName}.blob.core.windows.net/${containerName}?${sasString}`,
    window.azblob.StorageURL.newPipeline(
      new window.azblob.AnonymousCredential()
    )
  );
  return containerURL;
};

export const uploadFile = async ({
  containerURL,
  containerName,
  file,
  fileName,
  showNotificationForUploadInProgress,
  showNotificationForUploadFailed,
  notification
}) => {
  try {
    const blockSize = 4 * 1024 * 1024;
    const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, fileName);

    const response = await uploadBrowserDataToBlockBlob(
      Aborter.none,
      file,
      blockBlobURL,
      {
        blockSize,
        progress(evt) {
          notification.progress = (evt.loadedBytes * 100) / file.size;
          showNotificationForUploadInProgress(notification);
        }
      }
    );
    return response;
  } catch (error) {
    showNotificationForUploadFailed(notification);
    console.error(error);
  }
};
