// @flow
import * as React from 'react';
import { Button, Form, Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import userValidation from '../users/forms/userValidation';
import {
  InputPhoneNumberField,
  InputSelectField,
  InputTextField
} from '../../components/FormParts';
import Buttons from './Buttons';

import './Account.less';
import { RAD_SPECIALTY, USER_TYPE } from '../../utils/filters';

const SETTINGS_ACCOUNT_FORM = 'settingsAccountForm';

type InitialValues = {
  name: string,
  email: string,
  phoneNumber: string,
  title: string,
  facility: string
};

type Props = {
  loading: boolean,
  handleResetPassword: () => void,
  // Passed by redux to load the initial state of the form
  initialValues: InitialValues,
  // Values passed by reduxForm
  reset: () => void,
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean,
  userType: string
};

export default connect(state => ({
  initialValues: state.initialForm.settingsAccount
}))(
  reduxForm({
    form: SETTINGS_ACCOUNT_FORM,
    validate: userValidation
  })(
    ({
      loading,
      reset,
      handleResetPassword,
      handleSubmit,
      pristine,
      submitting,
      userType
    }: Props) => {
      const [role, setRole] = React.useState(userType);
      const [specialtyOpen, openSpecialty] = React.useState(false);
      const [userTypeOpen, openUserType] = React.useState(false);

      return (
        <Form layout='vertical'>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
                <Col>
                  <Field
                    style={{ width: 50 }}
                    name='title'
                    label='Title'
                    placeholder='Dr.'
                    component={InputTextField}
                  />
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <Field
                    name='name'
                    label='Name'
                    placeholder='Name'
                    component={InputTextField}
                  />
                </Col>
              </Row>
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='credentials'
                label='Credentials'
                placeholder='MD, ARRT, FACR, etc'
                component={InputTextField}
              />
            </Col>
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='phone'
                label='Mobile Number'
                component={InputPhoneNumberField}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='email'
                label='Email Address'
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='userType'
                label='User Role'
                placeholder='Please select...'
                component={InputSelectField(
                  USER_TYPE,
                  userTypeOpen,
                  () => openUserType(true),
                  () => openUserType(false)
                )}
                onChange={value => setRole(value)}
              />
            </Col>
            <Col className='gutter-row' span={9}>
              {role && role.toLowerCase() === 'radiologist' && (
                <Field
                  name='specialty'
                  label='Specialty'
                  showSearch
                  placeholder='Type to search or select'
                  component={InputSelectField(
                    RAD_SPECIALTY,
                    specialtyOpen,
                    () => openSpecialty(true),
                    () => openSpecialty(false)
                  )}
                  mode='multiple'
                />
              )}
            </Col>
          </Row>
          <Divider />
          <br />
          <Row gutter={16}>
            <Col>
              <div className='reset-password'>
                <Button onClick={handleResetPassword}>Reset password</Button>
                <p>
                  You will receive an email and a link to reset your password
                </p>
              </div>
            </Col>
          </Row>
          <Buttons
            pristine={pristine}
            submitting={submitting}
            loading={loading}
            handleCancel={reset}
            handleSave={handleSubmit}
          />
        </Form>
      );
    }
  )
);
