import * as React from 'react';
import { Form, Row, Col, Typography, Divider, Avatar } from 'antd';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { addGroupLetterhead, uploadGroupLetterhead } from '../../store/actions';
import type { Group, ReduxState } from '../../schemas';
import Uploader from '../../components/Uploader';
import Buttons from './Buttons';
import Labeled from '../../components/FormParts/Labeled';
// import { InputRadioButtonField } from '../../components/FormParts';
// import { GROUP_LETTERHEAD } from '../../utils/filters';

import './Print.less';

const { Text } = Typography;

type Props = {
  loading: boolean,
  groupId: string,
  handleSubmit: Object => void,
  addLetterheadAction: string => void,
  uploadLetterheadAction: () => void,
  oldLetterhead: string,
  newLetterhead: string,
  pristine: boolean,
  submitting: boolean,
  group: Group
};

const SETTINGS_PRINT_FORM = 'settingsPrintForm';

export default connect(
  (state: ReduxState) => ({
    groupId: state.profile.appMetadata.affiliatedGroup,
    oldLetterhead: state.group.letterhead,
    newLetterhead: state.group.newLetterhead,
    initialValues: state.initialForm.settingsGroup
  }),
  {
    addLetterheadAction: addGroupLetterhead,
    uploadLetterheadAction: uploadGroupLetterhead
  }
)(
  reduxForm({
    form: SETTINGS_PRINT_FORM
  })(
    ({
      groupId,
      addLetterheadAction,
      uploadLetterheadAction,
      handleSubmit,
      newLetterhead,
      oldLetterhead,
      pristine,
      submitting,
      group
    }: Props) => {
      const [letterheadChanged, setLetterheadChanged] = React.useState(false);
      const [letterhead, setLetterhead] = React.useState(oldLetterhead);
      const [footerSize, setFooterSize] = React.useState(100);
      const [headerSize, setHeaderSize] = React.useState(200);
      React.useEffect(() => {
        if (
          newLetterhead &&
          newLetterhead !== letterhead &&
          newLetterhead !== ''
        ) {
          setLetterhead(newLetterhead);
          setLetterheadChanged(true);
        } else if (
          oldLetterhead &&
          oldLetterhead !== '' &&
          oldLetterhead !== letterhead &&
          letterhead !== newLetterhead
        ) {
          setLetterhead(oldLetterhead);
          setLetterheadChanged(true);
        }
        if (group.headerSize && group.headerSize !== headerSize) {
          setHeaderSize(group.headerSize);
        }
        if (group.footerSize && group.footerSize !== footerSize) {
          setFooterSize(group.footerSize);
        }
      }, [
        oldLetterhead,
        newLetterhead,
        letterhead,
        group,
        footerSize,
        headerSize
      ]);

      const handleUpdate = () => {
        handleSubmit();
        uploadLetterheadAction();
        setLetterheadChanged(false);
      };

      const handleCancel = () => {
        uploadLetterheadAction();
        setLetterheadChanged(false);
      };

      return (
        <Form layout='vertical'>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={24}>
              <Text strong className='upload-letterhead-label'>
                Upload Group Letterhead
              </Text>
              <Labeled>
                <div className='uploader'>
                  <Uploader
                    containerName='grouplogos'
                    containerPath="default-letterheads"
                    blobName={groupId}
                    handleSuccess={addLetterheadAction}
                    showAccepted
                  />
                </div>
              </Labeled>
            </Col>
            <Col className='gutter-row' span={24}>
              {letterhead && <Avatar shape='square' size='large' src={letterhead} />}
            </Col>
          </Row>
          <Divider />
          <Buttons
            pristine={(pristine || submitting) && !letterheadChanged}
            submitting={false}
            loading={false}
            handleCancel={handleCancel}
            handleSave={handleUpdate}
          />
        </Form>
      );
    }
  )
);
