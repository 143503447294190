/* eslint-disable */
import * as React from 'react';
import { Form, Row, Col, Icon, Badge, Avatar, Typography } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Uploader from '../../components/Uploader';
import groupValidation from './groupValidation';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../components/FormParts';
import type { Group, ReduxState } from '../../schemas';
import Buttons from './Buttons';

import './Group.less';
import {
  addGroupLogo,
  removeGroupLogo,
  uploadGroupLogo
} from '../../store/actions';

const { Text } = Typography;

const SETTINGS_GROUP_FORM = 'settingsGroupForm';

type InitialValues = {};

type Props = {
  loading: boolean,
  groupId: string,
  group: Group,
  // Passed by redux to load the initial state of the form
  initialValues: InitialValues,
  // Values passed by reduxForm
  reset: () => void,
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean,
  addLogoAction: string => void,
  removeLogoAction: () => void,
  uploadLogoAction: () => void
};

export default connect(
  (state: ReduxState) => ({
    initialValues: state.initialForm.settingsGroup,
    groupId: state.profile.appMetadata.affiliatedGroup,
    group: state.group
  }),
  {
    addLogoAction: addGroupLogo,
    uploadLogoAction: uploadGroupLogo,
    removeLogoAction: removeGroupLogo
  }
)(
  reduxForm({
    form: SETTINGS_GROUP_FORM,
    validate: groupValidation
  })(
    ({
      loading,
      reset,
      handleSubmit,
      pristine,
      submitting,
      groupId,
      group,
      addLogoAction,
      removeLogoAction,
      uploadLogoAction
    }: Props) => {
      // const [typesOpen, openTypes] = React.useState(false);
      const [logoChanged, setLogoChanged] = React.useState(false);

      const handleUpdate = () => {
        setLogoChanged(false);
        handleSubmit();
        uploadLogoAction();
      };

      const handleUpload = (logo: string) => {
        addLogoAction(logo);
        setLogoChanged(true);
      };

      return (
        <Form layout='vertical'>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='name'
                label='Organisation Name'
                placeholder='Name'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='location'
                label='Country'
                placeholder='Complete address...'
                component={InputTextField}
              />
            </Col>
            {/* <Col className='gutter-row' span={9}>
              <Field
                name='facilityType'
                label='Facility Type'
                placeholder='Please select...'
                component={InputSelectField(
                  FACILITY_TYPE,
                  typesOpen,
                  () => openTypes(true),
                  () => openTypes(false)
                )}
              />
            </Col> */}
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='officeNumber'
                label='Office Number'
                component={InputPhoneNumberField}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='emailAddress'
                label='Email Address'
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='website'
                label='Website'
                type='url'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
          </Row>

          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={24}>
              <Text strong className='group-logo-label'>
                Group Logo
              </Text>
              <div className='uploader'>
                <Uploader
                  containerName='grouplogos'
                  containerPath='logo'
                  blobName={groupId}
                  handleSuccess={handleUpload}
                  showAccepted
                />
              </div>
              <div className='settings-image-show'>
                {group.logo !== '' ? (
                  <Badge
                    onClick={() => removeLogoAction()}
                    className='group-logo-badge'
                    count={
                      <Icon
                        type='close-circle'
                        theme='filled'
                        style={{ fontSize: '24px' }}
                      />
                    }
                  >
                    <Avatar shape='square' size='large' src={group.logo} />
                  </Badge>
                ) : (
                  <Avatar shape='square' size='large' src={group.logo} />
                )}
              </div>
            </Col>
          </Row>
          <Buttons
            pristine={pristine && !logoChanged}
            submitting={submitting}
            loading={loading}
            handleCancel={reset}
            handleSave={handleUpdate}
          />
        </Form>
      );
    }
  )
);
