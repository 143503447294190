import auth0 from 'auth0-js';

import decode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { config } from '../../utils';
import type { User } from '../../schemas';
import {
  deleteProfileFromLocalStorage,
  saveProfileToLocalStorage,
  saveSSODataToLocalStorage
} from '../../store/storage';
import { showError } from '../../store/helpers';
import { getSpecialty } from '../transformers';

const auth = new auth0.WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId, // AlemHealth Connect
  connection: config.auth0.connection,
  audience: config.auth0.audience,
  // eslint-disable-next-line func-names
  redirectUri: (function() {
    const url = window.location.href.split('/');
    return `${url[0]}//${url[2]}/auth0-callback`;
  })(),
  responseType: 'token id_token',
  scope: 'openid email profile'
});

export const authSignUp = (payload, callback) => auth.signup(payload, callback);

export const authLogin = (username, password, callback) =>
  auth.login(
    {
      realm: config.auth0.connection,
      username,
      password
    },
    callback
  );

export const processAuth = authResult => {
  // eslint-disable-next-line no-console
  auth.client.userInfo(authResult.accessToken, async (error, profile) => {
    if (error) {
      showError(error);
    } else {
      if (profile) {
        Sentry.configureScope(scope => {
          scope.setTag('userEmail', profile.email);
        });
      }
      const userMetadata = profile[`${config.auth0.namespace}user_meta_data`];
      const appMetadata = profile[`${config.auth0.namespace}app_meta_data`];
      saveProfileToLocalStorage({
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt: new Date().getTime() + authResult.expiresIn * 1000,
        email: profile.email,
        id: profile.sub,
        name: profile[`${config.auth0.namespace}name`],
        nickname: profile[`${config.auth0.namespace}nickname`],
        avatar: profile[`${config.auth0.namespace}picture`],
        logins_count: profile[`${config.auth0.namespace}logins_count`],
        userMetadata: {
          name: userMetadata.name,
          phoneNumber: userMetadata.phone_number,
          prefix: userMetadata.prefix,
          userType: userMetadata.user_type,
          homeFacility: userMetadata.home_facility,
          signature: userMetadata.signature,
          title: userMetadata.title,
          credentials: userMetadata.credentials,
          specialty: getSpecialty(userMetadata.specialty)
        },
        appMetadata: {
          admin: appMetadata.admin,
          main: appMetadata.main,
          affiliatedGroup: appMetadata.affiliated_group,
          approvedFacilities: appMetadata.approved_facilities,
          associatedGroups: appMetadata.associated_groups
        }
      });
    }
  });
};

export const handleAuthentication = (nextState, history) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        processAuth(authResult);
      } else if (err) {
        history.replace('/login');
        showError(err.error);
      }
    });
  }
};

export const checkSession = (setProfileCallback: User => void) => {
  auth.checkSession(
    {
      sso: false,
      responseType: 'token id_token',
      redirect: false,
      audience: config.auth0.audience,
      scope: 'openid email user_metadata app_metadata picture',
      prompt: 'none'
    },
    (err, authResult) => {
      if (!err && authResult && authResult.accessToken && authResult.idToken) {
        processAuth(authResult, setProfileCallback);
      } else {
        showError(err);
      }
    }
  );
};

export const getSSO = () => {
  auth0.getSSOData((err, ssoData) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log(`GET SSO INFO FAILED WITH ERROR ${err.message}`);
    } else if (!ssoData.sso) {
      // eslint-disable-next-line no-console
      console.log('UNABLE TO RETRIEVE SSO DATA');
    } else {
      saveSSODataToLocalStorage(ssoData.sso);
    }
  });
};

export const checkAuthenticated = (
  token: string,
  onSucceed: User => void
): boolean => {
  if (!token) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (Date.now() >= exp * 1000) {
      deleteProfileFromLocalStorage();
      return false;
    }
    if (Date.now() + 3600000 >= exp * 1000) {
      // An hour
      checkSession(onSucceed);
    }
  } catch (e) {
    return false;
  }
  return true;
};
