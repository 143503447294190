import React from 'react';
import { Typography, Row, Col } from 'antd';
import { t } from 'i18next';
import AddUser from './AddUser';
import TableUser from './TableUser';
import TableInvites from './TableInvites';
import './index.less';

const { Title } = Typography;
function Users() {
  return (
    <div className='user-management-index'>
      <div className='user-management-header'>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <p>{t('user_administration.add_organisation_rights')}</p>
          </Col>
          <Col /* className='page-cta-wrapper' */>
            <AddUser />
          </Col>
        </Row>
      </div>
      <Row style={{ marginBottom: '20px' }}>
        <TableInvites />
      </Row>
      <div className='user-management-header'>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <Title level={3} style={{ color: '#666', fontWeight: 400 }}>
              {t('user_administration.current_users')}
            </Title>
          </Col>
        </Row>
      </div>
      <Row>
        <TableUser />
      </Row>
    </div>
  );
}

export default Users;
