const getStripeEnv = (key: string) =>
  process.env[
    `REACT_APP_STRIPE_${
      process.env.REACT_APP_STRIPE_PRODUCTION === '0' ? 'TEST_' : ''
    }${key}`
  ];

export type StripeSpec = {
  id: string,
  priceId: string,
  quantity: number,
  name: string,
  currency: string,
  price: number,
  features: Array<string>,
  learnMore: string,
  showButton: boolean
};

export const stripePlanSpecifications = (
  quantity: number,
  currency: string
): StripeSpec[] => [
  {
    id: getStripeEnv('CONNECT_PRODUCT'),
    priceId: getStripeEnv('CONNECT_PRICE_M'),
    name: 'AlemHealth Connect',
    price: 25,
    quantity,
    currency,
    features: [
      'Unlimited Studies per Month',
      'Windows, Android or web DICOM Uploader',
      'Online FDA-approved Viewing and Custom Report Templates'
    ],
    learnMore: '',
    showButton: true,
    showModal: false
  },
  {
    id: getStripeEnv('BOX_PRODUCT'),
    priceId: getStripeEnv('BOX_PRICE'),
    name: 'AlemBox Pro',
    price: 79,
    quantity,
    currency,
    features: [
      'AlemBox Radiology Gateway Hardware Included',
      'RIS/PACS & HL7 Integration',
      'Unlimited Studies Per Month',
      'Plus all the features of AlemHealth Connect'
    ],
    learnMore: '',
    showButton: true,
    showModal: true
  },
  {
    id: getStripeEnv('HUB_PRODUCT'),
    priceId: getStripeEnv('HUB_PRICE'),
    name: 'AlemHub Enterprise',
    price: 299,
    quantity,
    currency,
    features: [
      'AlemHub Radiology Reporting RIS/PACS Server included',
      'Locally-hosted FDA-approved Viewer',
      'Unlimited Studies Per Month',
      'AlemHealth Connect'
    ],
    learnMore: '',
    showButton: true,
    showModal: true
  }
];
