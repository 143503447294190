// @flow
import React from 'react';
import { Modal, Spin, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { confirmDeletion } from '../../components/confirmations';

import { getUserByIdAction, deleteUserAction } from '../../store/actions';
import EditUser from './EditUser';
import ActionLink from '../../components/ActionLink';
import { ReduxState, User } from '../../schemas';

type Props = {
  userId: string,
  data: User,
  loading: boolean
};

type State = {
  visible: boolean,
  editUser: boolean
};

export default connect(
  (state: ReduxState) => ({
    data: state.users.current,
    loading: state.loading.getUserById
  }),
  {
    getAction: getUserByIdAction,
    deleteAction: deleteUserAction
  }
)(
  class ViewUser extends React.Component<Props, State> {
    state = {
      visible: false,
      editUser: false
    };

    showModal = () => {
      const { getAction, userId } = this.props;
      this.setState({ visible: true });
      getAction(userId);
    };

    handleCancel = () => {
      this.setState({ visible: false });
    };

    handleCancelEdit = () => {
      this.setState({ editUser: false });
    };

    handleOk = () => {
      this.setState({ visible: false, editUser: true });
    };

    handleDelete = () => {
      const { deleteAction, data } = this.props;
      confirmDeletion(deleteAction, data.id, data.name, data.name);
    };

    render() {
      const { data, loading } = this.props;
      const { editUser, visible } = this.state;
      if (Object.entries(data).length === 0 || loading) {
        return (
          <div>
            <a className='ant-dropdown-link' onClick={this.showModal}>
              {t('user_administration.field.view')}
            </a>
            <Modal
              visible={this.state.visible}
              title='User Details'
              okText='Edit'
              footer={null}
              onCancel={this.handleCancel}
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </div>
        );
      }
      const disableDelete = data.appMetadata.main === '1';
      return (
        <div>
          <EditUser visible={editUser} onCancel={this.handleCancelEdit} />
          <ActionLink
            className='custom-link'
            click={this.showModal}
            text='View'
          />
          <Modal
            title='User Details'
            okText='Edit'
            visible={visible}
            onCancel={this.handleCancel}
            destroyOnClose
            footer={
              <Row type='flex' justify='space-around' align='middle'>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Button
                    className='custom-link'
                    aling='left'
                    onClick={this.handleDelete}
                    disabled={disableDelete}
                  >
                    {t('user_administration.field.delete_user')}
                  </Button>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  <Button onClick={this.handleCancel}>
                    {t('button.close')}
                  </Button>
                  <Button onClick={this.handleOk}>{t('button.edit')}</Button>
                </Col>
              </Row>
            }
          >
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
                  <Col style={{ width: 50 }}>
                    <p>{t('user_administration.field.title')}</p>
                    <p style={{ color: 'black' }}>{data.userMetadata.title}</p>
                  </Col>
                  <Col>
                    <p>{t('user_administration.field.name')}</p>
                    <p style={{ color: 'black' }}>{data.name}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.credentials')}</p>
                <p style={{ color: 'black' }}>
                  {data.userMetadata.credentials}
                </p>
              </Col>
            </Row>
            <br />
            {data.userMetadata.specialty ? (
              <div>
                <Row gutter={32}>
                  <Col className='gutter-row' span={12}>
                    <p>{t('user_administration.field.role')}</p>
                    <p style={{ color: 'black' }}>
                      {data.userMetadata.userType}
                    </p>
                  </Col>
                  <Col className='gutter-row' span={12}>
                    <p>{t('user_administration.field.specialty')}</p>
                    <p style={{ color: 'black' }}>
                      {typeof data.userMetadata.specialty === 'object'
                        ? data.userMetadata.specialty.join(', ')
                        : data.userMetadata.specialty}
                    </p>
                  </Col>
                </Row>
                <br />
              </div>
            ) : null}
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.email_adress')}</p>
                <p style={{ color: 'black' }}>{data.email}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.mobile_number')}</p>
                <p style={{ color: 'black' }}>
                  {data.userMetadata.phoneNumber}
                </p>
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.home_facility')}</p>
                <p style={{ color: 'black' }}>
                  {data.userMetadata.homeFacility || '—'}
                </p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.admin_access')}</p>
                <p style={{ color: 'black' }}>
                  {(data.appMetadata.admin === '1' ? 'Yes' : '') +
                    (data.appMetadata.main === '1' ? ' (Main)' : '')}
                </p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.delete_access')}</p>
                <p style={{ color: 'black' }}>
                  {data.appMetadata.allowed_to_delete_study === '1'
                    ? 'Yes'
                    : 'No'}
                </p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t('user_administration.field.available_assignment')}</p>
                <p style={{ color: 'black' }}>
                  {data.appMetadata.availableForAssignment === '1'
                    ? 'Yes'
                    : 'No'}
                </p>
              </Col>
            </Row>
          </Modal>
        </div>
      );
    }
  }
);
