import { call, put, select, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '../helpers';
import { RevokeAdminRightsAction } from '../actions';
import * as actions from '../actions';
import {
  changeMainAdminApiCall,
  createUserApiCall,
  deleteUserApiCall,
  getAllUsersApiCall,
  getUserByIdApiCall,
  getUserGroupApiCall,
  getUsersApiCall,
  resetPasswordApiCall,
  updateUserApiCall
} from '../../api';
import { getCurrentUser, getUsersLastSearch, getAffiliatedGroup} from '../selectors';
import { postInviteToZapierAPICall } from '../../api/endpoints/zapier';

export function* getUsersSaga(action) {
  yield put({ type: actions.GET_USERS_LOADING, loading: true });
  try {
    const { perPage, page, sort, query } = action;
    const profile = yield select(getCurrentUser);
    const users = yield call(
      getUsersApiCall,
      profile,
      perPage,
      page,
      sort,
      query
    );
    yield put({
      type: actions.UPDATE_LAST_USERS_SEARCH,
      search: { perPage, page, sort, query }
    });
    yield put({
      type: actions.GET_USERS_SUCCESS,
      data: users
    });
  } catch (error) {
    yield put({ type: actions.GET_USERS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USERS_LOADING, loading: false });
  }
}

function* getAllUsersSaga() {
  yield put({ type: actions.GET_USERS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const users = yield call(getAllUsersApiCall, profile);
    yield put({ type: actions.GET_ALL_USERS_SUCCESS, data: users });
  } catch (error) {
    yield put({ type: actions.GET_ALL_USERS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USERS_LOADING, loading: false });
  }
}

function* getUserByIdSaga(action) {
  yield put({ type: actions.GET_USER_BY_ID_LOADING, loading: true });
  try {
    const { userId } = action;
    const profile = yield select(getCurrentUser);
    const user = yield call(getUserByIdApiCall, profile, userId);
    yield put({ type: actions.GET_USER_BY_ID_SUCCESS, data: user });
  } catch (error) {
    yield put({ type: actions.GET_USER_BY_ID_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USER_BY_ID_LOADING, loading: false });
  }
}

function* addUserSaga(action) {
  yield put({ type: actions.CREATE_USER_LOADING, loading: true });
  try {
    const { user } = action;
    const profile = yield select(getCurrentUser);
    const group = yield select(getAffiliatedGroup);
    const response = yield call(getUserGroupApiCall, profile);
    user.organization_name = response.name;
    yield call(postInviteToZapierAPICall, {
      jsonContent: {
        inviter_name: profile.name,
        inviter_email: profile.email,
        organization_guid: group,
        invitee_name: user.name,
        invitee_emails: user.invitee_email,
        invitee_role: user.userMetadata.userType,
        access_level: user.userMetadata.inviteUserWith
      }
    });

    yield call(createUserApiCall, profile, user);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.ADD_USER_SUCCESS });
    yield put({
      type: actions.GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST
    });
    yield call(showSuccess, 'Invitation Sent');
  } catch (error) {
    yield put({ type: actions.ADD_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.CREATE_USER_LOADING, loading: false });
  }
}

function* editUserSaga(action) {
  yield put({ type: actions.UPDATE_USER_LOADING, loading: true });
  try {
    const { userUpdate, userId } = action;
    const profile = yield select(getCurrentUser);
    yield call(updateUserApiCall, profile, userId, userUpdate);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.EDIT_USER_SUCCESS });
    yield call(showSuccess, `User has been updated`);
  } catch (error) {
    yield put({ type: actions.EDIT_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.UPDATE_USER_LOADING, loading: false });
  }
}

function* deleteUserSaga(action) {
  yield put({ type: actions.DELETE_USER_LOADING, loading: true });
  try {
    const { userId } = action;
    const profile = yield select(getCurrentUser);
    yield call(deleteUserApiCall, profile, userId);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.DELETE_USER_SUCCESS });
    yield call(showSuccess, 'User has been deleted');
  } catch (error) {
    yield put({ type: actions.DELETE_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.DELETE_USER_LOADING, loading: false });
  }
}

function* resetPasswordSaga(action) {
  try {
    const { email } = action;
    const profile = yield select(getCurrentUser);
    const { message } = yield call(resetPasswordApiCall, profile, email);
    yield put({ type: actions.RESET_USER_PASSWORD_SUCCESS });
    yield call(showSuccess, message);
  } catch (error) {
    yield put({ type: actions.DELETE_USER_FAILED, error });
    yield call(showError, error);
  }
}

function* revokeAdminRightsSaga(action: RevokeAdminRightsAction) {
  const { oldAdminId, newAdminId } = action;
  const profile = yield select(getCurrentUser);
  try {
    yield call(changeMainAdminApiCall, profile, oldAdminId, newAdminId);
    yield put({ type: actions.REVOKE_ADMIN_RIGHTS_SUCCESS });
  } catch (error) {
    yield call(showError, error);
    yield put({ type: actions.REVOKE_ADMIN_RIGHTS_FAILED });
  }
}

export default function* internalRadiologistsSaga() {
  yield takeLatest(actions.GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(actions.GET_ALL_USERS_REQUEST, getAllUsersSaga);
  yield takeLatest(actions.GET_USER_BY_ID_REQUEST, getUserByIdSaga);
  yield takeLatest(actions.ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(actions.EDIT_USER_REQUEST, editUserSaga);
  yield takeLatest(actions.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(actions.RESET_USER_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(actions.REVOKE_ADMIN_RIGHTS_REQUEST, revokeAdminRightsSaga);
}
