import React from 'react';
import { Row, Col, Button } from 'antd';
import CardFormWrapper from '../partials/CardFormWrapper';
import './index.less';

const USE_HOSTED_UPLOADER_FORM_NAME = 'useHostedUploaderForm';

function HostedUploader({ groupId, disabled }) {
  return (
    <CardFormWrapper
      formName={USE_HOSTED_UPLOADER_FORM_NAME}
      title='Use our hosted uploader'
      text='Link to the below page to open up a separate page with a self-contained uploader.'
      value={`<a href="https://embed.alem.health/?id=${groupId}">Request Second Opinion</a>`}
      type='hosted'
      disabled={disabled}
    >
      <Row type='flex' align='middle' gutter={24}>
        <Col>
          <div className='label'>Preview</div>
        </Col>
        <Col>
          <Button
            disabled={disabled}
            href={`https://embed.alem.health/?id=${groupId}`}
          >
            View page
          </Button>
        </Col>
      </Row>
    </CardFormWrapper>
  );
}

export default HostedUploader;
