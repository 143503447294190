import * as actions from '../actions/loadingActions';
import initialState from '../state';

const reduceLoading = (previousLoading, loading, name) => {
  if (loading) {
    return true;
  }
  return Object.keys(previousLoading)
    .filter(key => key !== name && key !== 'global')
    .reduce((prev, key) => {
      if (previousLoading[key]) {
        prev = true;
      }
      return prev;
    }, false);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PHYSICIANS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getPhysicians'),
        getPhysicians: action.loading
      };
    case actions.GET_PHYSICIAN_BY_ID_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getPhysicianById'),
        getPhysicianById: action.loading
      };
    case actions.CREATE_PHYSICIAN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'createPhysician'),
        createPhysician: action.loading
      };
    case actions.UPDATE_PHYSICIAN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'updatePhysician'),
        updatePhysician: action.loading
      };
    case actions.DELETE_PHYSICIAN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deletePhysician'),
        deletePhysician: action.loading
      };
    case actions.LOGIN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'login'),
        login: action.loading
      };
    case actions.CHANGE_PLAN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'changePlan'),
        changePlan: action.loading
      };
    case actions.GET_USERS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getUsers'),
        getUsers: action.loading
      };
    case actions.GET_USER_BY_ID_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getUserById'),
        getUserById: action.loading
      };
    case actions.CREATE_USER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'createUser'),
        createUser: action.loading
      };
    case actions.UPDATE_USER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'updateUser'),
        updateUser: action.loading
      };
    case actions.DELETE_USER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deleteUser'),
        deleteUser: action.loading
      };
    case actions.UPDATE_SETTINGS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'updateSettings'),
        updateSettings: action.loading
      };
    case actions.GET_EXTERNAL_RADS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'externalRads'),
        externalRads: action.loading
      };
    case actions.GET_INTERNAL_RADS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'internalRads'),
        internalRads: action.loading
      };
    case actions.GET_PROVIDERS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'providers'),
        providers: action.loading
      };
    case actions.GET_EXTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'externalRad'),
        externalRad: action.loading
      };
    case actions.GET_INTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'internalRad'),
        internalRad: action.loading
      };
    case actions.GET_PROVIDER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'provider'),
        provider: action.loading
      };
    case actions.ADD_EXTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'addExternalRad'),
        addExternalRad: action.loading
      };
    case actions.ADD_INTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'addInternalRad'),
        addInternalRad: action.loading
      };
    case actions.ADD_PROVIDER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'addProvider'),
        addProvider: action.loading
      };
    case actions.EDIT_EXTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'editExternalRad'),
        editExternalRad: action.loading
      };
    case actions.EDIT_INTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'editInternalRad'),
        editInternalRad: action.loading
      };
    case actions.DELETE_EXTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deleteExternalRad'),
        deleteExternalRad: action.loading
      };
    case actions.DELETE_INTERNAL_RAD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deleteInternalRad'),
        deleteInternalRad: action.loading
      };
    case actions.DELETE_PROVIDER_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deleteProvider'),
        deleteProvider: action.loading
      };
    case actions.REQUEST_PROVIDER_SERVICE_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'requestProvider'),
        requestProvider: action.loading
      };
    case actions.GET_PLAN_INFORMATION_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'plan'),
        plan: action.loading
      };
    case actions.CHANGE_BILLING_ADDRESS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'billingAddress'),
        billingAddress: action.loading
      };
    case actions.CHANGE_PAYMENT_CARD_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'paymentCard'),
        paymentCard: action.loading
      };
    case actions.GET_STRIPE_CLIENT_SECRET_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'clientSecret'),
        clientSecret: action.loading
      };
    case actions.GET_FACILITIES_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'facilities'),
        facilities: action.loading
      };
    case actions.GET_FACILITY_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'facility'),
        facility: action.loading
      };
    case actions.POST_RESEND_MEDICAL_GROUPS_INVITATION_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'resendMedicialGroup'),
        facility: action.loading
      };
    case actions.ADD_FACILITY_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'addFacility'),
        addFacility: action.loading
      };
    case actions.EDIT_FACILITY_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'editFacility'),
        editFacility: action.loading
      };
    case actions.DELETE_FACILITY_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'deleteFacility'),
        deleteFacility: action.loading
      };
    case actions.GET_GROUP_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getGroup'),
        getGroup: action.loading
      };
    case actions.UPDATE_GROUP_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'updateGroup'),
        updateGroup: action.loading
      };
    case actions.UPGRADE_PLAN_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'upgradePlan'),
        upgradePlan: action.loading
      };
    case actions.GET_PORTAL_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getPortal'),
        getPortal: action.loading
      };
    case actions.SET_BANK_PAYMENT_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'setBankPayment'),
        setBankPayment: action.loading
      };
    case actions.GET_ACTIVE_STUDIES_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'activeStudies'),
        activeStudies: action.loading
      };
    case actions.GET_GROUP_DETAIL_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'groupDetail'),
        groupDetail: action.loading
      };
    case actions.GET_OVERVIEW_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'overview'),
        overview: action.loading
      };
    case actions.GET_RADIOLOGIST_PERFORMANCE_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'radiologistPerformance'),
        radiologistPerformance: action.loading
      };
    case actions.GET_STUDY_DETAILS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'studyDetails'),
        studyDetails: action.loading
      };
    case actions.GET_STRIPE_DATA_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getStripeData'),
        getStripeData: action.loading
      };
    case actions.CREATE_STRIPE_SESSION_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'createStripeSession'),
        createStripeSession: action.loading
      };
    case actions.SECOND_OPINION_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'secondOpinion'),
        secondOpinion: action.loading
      };
    case actions.ONBOARDING_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'onboarding'),
        onboarding: action.loading
      };
    case actions.CREATE_DEVICE_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'createDevice'),
        createDevice: action.loading
      };
    case actions.UPDATE_DEVICE_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'editDevice'),
        editDevice: action.loading
      };
    case actions.DELETE_DEVICE_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'editDevice'),
        deleteDevice: action.loading
      };
    case actions.GET_DEVICES_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getDevices'),
        getDevices: action.loading
      };

    case actions.GET_SOURCE_FACILITIES_CHART_LOADING:
      return {
        ...state,
        global: reduceLoading(
          state,
          action.loading,
          'getSourceFacilitiesChart'
        ),
        getSourceFacilitiesChart: action.loading
      };
    case actions.GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING:
      return {
        ...state,
        global: reduceLoading(
          state,
          action.loading,
          'getSourceReferringFacilitiesChart'
        ),
        getSourceReferringFacilitiesChart: action.loading
      };
    case actions.GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING:
      return {
        ...state,
        global: reduceLoading(
          state,
          action.loading,
          'getSourceReferringPhysiciansChart'
        ),
        getSourceReferringPhysiciansChart: action.loading
      };
    case actions.GET_REFERRING_PHYSICIANS_LIST_LOADING:
      return {
        ...state,
        global: reduceLoading(
          state,
          action.loading,
          'getReferringPhysiciansList'
        ),
        getReferringPhysiciansList: action.loading
      };
    case actions.GET_REFERRING_FACILITIES_LIST_LOADING:
      return {
        ...state,
        global: reduceLoading(
          state,
          action.loading,
          'getReferringFacilitiesList'
        ),
        getReferringFacilitiesList: action.loading
      };
    case actions.GET_REFERRING_PHYSICIANS_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getReferringPhysicians'),
        getReferringPhysicians: action.loading
      };
    case actions.GET_REFERRING_FACILITIES_LOADING:
      return {
        ...state,
        global: reduceLoading(state, action.loading, 'getReferringFacilities'),
        getReferringFacilities: action.loading
      };
    default:
      return state;
  }
};
