/* eslint-disable */
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import * as actions from '../actions';
import {
  deleteProfileFromLocalStorage,
  getProfileFromLocalStorage,
  saveProfileToLocalStorage
} from '../storage';
import Logger from '../../utils/logger';
import { accountSettingsToUser } from '../../api/transformers';
import {
  updateUserApiCall,
  forgotPasswordApiEndpoint,
  getStudyDetailsApiCall
} from '../../api';
import { getUsersSaga } from './usersSaga';
import { showError, showSuccess } from '../helpers';

import type { UserProfile } from '../../schemas';
import { authLogin } from '../../api/auth/auth_v2';
import { getAddress, getCurrentUser, getUsersLastSearch } from '../selectors';
import { getAzureAddressApiCall } from '../../api/endpoints/options';
import { dateFilterStrings } from '../constants';

function* getLoginDataSaga() {
  let profile: UserProfile = yield call(getProfileFromLocalStorage);
  while (!profile) {
    yield delay(500);
    profile = yield call(getProfileFromLocalStorage);
  }
  yield put({ type: actions.LOGIN_SUCCEED, data: profile });
}

function* logoutSaga() {
  yield call(deleteProfileFromLocalStorage);
  yield call(() => {
    window.location.href = '/';
  });
}

function* loginSaga(action) {
  const { username, password } = action.data;
  yield call(authLogin, username, password, error => {
    if (error) {
      showError('Wrong login credential provided.');
    }
  });
}

function* loginSuccessSaga(action) {
  const { data } = action;
  yield call(saveProfileToLocalStorage, data);
}

function* forgotPasswordSaga(action) {
  const {
    data: { email }
  } = action;
  try {
    yield call(forgotPasswordApiEndpoint, email);
    yield put({ type: actions.FORGOT_PASSWORD_SUCCESS });
    yield call(
      showSuccess,
      `An email has be sent to ${email} to reset the password`
    );
  } catch (e) {
    yield put({ type: actions.FORGOT_PASSWORD_FAILED });
    showError(e);
  }
}

const notificationsSettingsProvisionalApi = () => ({});

function* readAllNotifications() {
  yield call(Logger.log, 'READ ALL NOTIFICATIONS SAGA');
}

function* clearNotification(action) {
  yield call(
    Logger.log,
    `CLEAR NOTIFICATION SAGA, NOTIFICATION ID: ${action.notification_id}`
  );
}

function* updateAccountSettings(action) {
  yield put({ type: actions.UPDATE_SETTINGS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const userId = profile.id;
    const userUpdate = yield call(
      accountSettingsToUser,
      action.settings,
      profile
    );
    yield call(updateUserApiCall, profile, userId, userUpdate);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.ACCOUNT_SETTINGS_SUCCESS, userUpdate });
    yield call(showSuccess, 'Account settings updated');
  } catch (error) {
    yield put({ type: actions.ACCOUNT_SETTINGS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.UPDATE_SETTINGS_LOADING, loading: false });
  }
}

function* updateNotificationsSettings(action) {
  yield put({ type: actions.UPDATE_SETTINGS_LOADING, loading: true });
  try {
    const id = yield select(getCurrentUser);
    yield call(notificationsSettingsProvisionalApi, action.settings, id);
    yield put({ type: actions.NOTIFICATIONS_SETTINGS_SUCCESS });
    yield call(showSuccess, 'Notifications settings updated');
  } catch (error) {
    yield put({ type: actions.NOTIFICATIONS_SETTINGS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.UPDATE_SETTINGS_LOADING, loading: false });
  }
}

function* getAzureAddressSaga() {
  const address = yield select(getAddress);
  if (!address) {
    try {
      const profile = yield select(getCurrentUser);
      const address = yield call(getAzureAddressApiCall, profile);
      yield put({ type: actions.GET_AZURE_ADDRESS_SUCCESS, address });
    } catch (e) {
      yield put({ type: actions.GET_AZURE_ADDRESS_FAILED, e });
      yield call(showError, e);
    }
  }
}

function* getUserStudyCountSaga(action) {
  const { startDate, endDate } = action.payload;
  try {
    const profile = yield select(getCurrentUser);
    const studyDetails = yield call(
      getStudyDetailsApiCall,
      profile,
      dateFilterStrings.CUSTOM,
      [startDate, endDate]
    );
    let studyCount = 0;
    if (
      studyDetails &&
      studyDetails.studyCount &&
      studyDetails.studyCount.length > 0
    ) {
      studyCount = studyDetails.studyCount.reduce((total, item) => {
        return total + item.studyCount;
      }, 0);
    }
    yield put({ type: actions.GET_USER_STUDY_COUNT_SUCCESS, studyCount });
  } catch (e) {
    yield put({ type: actions.GET_USER_STUDY_COUNT_FAILED, e });
    yield call(showError, e);
  }
}

export default function* profileSaga() {
  yield takeLatest(actions.GET_LOGIN_DATA, getLoginDataSaga);
  yield takeLatest(actions.LOGIN_REQUEST, loginSaga);
  yield takeLatest(actions.LOGIN_SUCCEED, loginSuccessSaga);
  yield takeLatest(actions.LOGOUT, logoutSaga);
  yield takeLatest(actions.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);

  yield takeLatest(actions.CLEAR_NOTIFICATION, clearNotification);
  yield takeLatest(actions.READ_ALL_NOTIFICATIONS, readAllNotifications);
  yield takeLatest(actions.ACCOUNT_SETTINGS_REQUEST, updateAccountSettings);
  yield takeLatest(
    actions.NOTIFICATIONS_SETTINGS_REQUEST,
    updateNotificationsSettings
  );
  yield takeLatest(actions.GET_AZURE_ADDRESS_REQUEST, getAzureAddressSaga);
  yield takeLatest(actions.GET_USER_STUDY_COUNT_REQUEST, getUserStudyCountSaga);
}
