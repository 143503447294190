import React from 'react';
import { Select } from 'antd';
import i18next from 'i18next';
import { ReactComponent as SP } from '../../../static/images/spFlag.svg';
import { ReactComponent as UK } from '../../../static/images/ukFlag.svg';
import { ReactComponent as FR } from '../../../static/images/frFlag.svg';

const languageMap = [
  { label: 'English', value: 'en', icon: <UK /> },
  { label: 'Español', value: 'es', icon: <SP /> },
  { label: 'Français', value: 'fr', icon: <FR /> }
];
const { Option } = Select;

const ChangeLanguage = props => {
  const changeLang = e => {
    i18next.changeLanguage(e);
  };

  return (
    <Select
      defaultValue='en'
      className='change-language'
      style={{ width: '120px' }}
      onChange={changeLang}
    >
      {languageMap.map(item => (
        <Option value={item.value}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {item.icon} {item.label}
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default ChangeLanguage;
