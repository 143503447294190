/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';
import { Typography, Switch, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReduxState, SecondOpinion } from '../../../schemas';

import '../index.less';
import IndexSecondOpinionUploader from './second-opinion/uploader-settings';
import {
  getSecondOpinionSettingRequest,
  updateOnboardingSecondOpinionSettingRequest
} from '../../../store/actions';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onBack: Function,
  onSubmit: Function,
  onSkip: Function,
  getSecondOpinionSetting: Function,
  updateSecondOpinionSetting: Function,
  secondOpinion: SecondOpinion
};

const { Title, Text } = Typography;

const WebUploader = ({
  data,
  activeId,
  onBack,
  onSubmit,
  onSkip,
  getSecondOpinionSetting,
  updateSecondOpinionSetting,
  secondOpinion,
  change
}: Props) => {
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    getSecondOpinionSetting();
  }, []);

  const setUploaderStatus = async checked => {
    updateSecondOpinionSetting({ status: checked });
    setIsActive(checked);
  };

  return (
    <>
      <Title
        className='onboarding-action-title'
        style={{ display: 'flex', justifyContent: 'space-between' }}
        level={3}
      >
        Activate Web Uploader{' '}
        <div>
          <Switch
            checkedChildren='on'
            unCheckedChildren='off'
            checked={secondOpinion.status}
            onChange={setUploaderStatus}
          />
        </div>
      </Title>
      <Text className='onboarding-action-subtitle'>
        With an AlemHealth Connect subscription, you get a customised DICOM
        uploader that supports CD uploads, Google Drive, Box, and Dropbox
        syncing so patients and facilities can quickly and easily send you their
        studies.
      </Text>
      <IndexSecondOpinionUploader disabled={!isActive} />
      <div className='onboarding-action-buttons'>
        <Button type='link' size='large' onClick={onBack}>
          Go Back
        </Button>

        <Button type='primary' size='large' onClick={onSubmit}>
          Continue
        </Button>
      </div>
      <div className='onboarding-action-buttons'>
        <Button className='ant-btn-line' size='large' onClick={onSkip}>
          Skip for Now
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    profile: state.profile,
    secondOpinion: state.secondOpinion.secondOpinionSetting
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSecondOpinionSetting: getSecondOpinionSettingRequest,
      updateSecondOpinionSetting: updateOnboardingSecondOpinionSettingRequest
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(WebUploader);
