import AuthService from '../index';
import Logger from '../../utils/logger';
import type { UserProfile } from '../../schemas/userTypes';
import {
  jsonToStripeDataInformation,
  jsonToStripeInvoiceData
} from '../transformers';
import type {
  ApiService,
  StripePaymentRequest,
  StripeSessionRequest
} from '../../schemas';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/payments';

export const getStripeDataEndpoint = async (profile: UserProfile) => {
  Logger.log('GET stripe data api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE).then(i => {
    return jsonToStripeDataInformation(i);
  });
};

export const getStripeInvoicesEndpoint = async (
  profile: UserProfile,
  limit: number,
  first: string,
  last: string
) => {
  Logger.log(
    `GET stripe invoices api called: ${limit} invoices ${
      first ? `from ${first}` : `to ${last}`
    }`
  );
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(
      API_SERVICE,
      `${BASE_ROUTE}/invoices?limit=${limit}&${
        last ? `after=${last}` : first && `before=${first}`
      }`
    )
    .then(i => {
      return jsonToStripeInvoiceData(i);
    });
};

export const createStripeChargeEndpoint = async (
  profile: UserProfile,
  payload: StripeSessionRequest
) => {
  Logger.log(`POST create stripe charge called with: ${payload}`);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/charge`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(i => i.token);
};

export const createStripePaymentEndpoint = async (
  profile: UserProfile,
  payload: StripePaymentRequest
) => {
  Logger.log(`POST create stripe payment called with: ${payload}`);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/payment`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(i => i.token);
};

export const checkStripeUnsuscribeEndpoint = async (profile: UserProfile) => {
  Logger.log('PATCH check stripe unsuscribe called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(
    API_SERVICE,
    `${BASE_ROUTE}/check-unsuscribe`,
    {
      method: 'PATCH'
    }
  );
};
