import { put, select, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/paymentsActions';
import * as lActions from '../actions/loadingActions';
import { getCurrentUser } from '../selectors';
import {
  checkStripeUnsuscribeEndpoint,
  getStripeDataEndpoint,
  getStripeInvoicesEndpoint
} from '../../api/endpoints/payments';

function* getStripeDataSaga() {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const payload = yield call(getStripeDataEndpoint, profile);
    yield put({ type: actions.GET_STRIPE_DATA_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.GET_STRIPE_DATA_FAILURE, error });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

function* getStripeInvoicesSaga(action: {
  type: string,
  limit: number,
  first: string,
  last: string
}) {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  const { limit, first, last } = action;
  const profile = yield select(getCurrentUser);
  try {
    const payload = yield call(
      getStripeInvoicesEndpoint,
      profile,
      limit,
      first,
      last
    );
    yield put({ type: actions.GET_STRIPE_INVOICES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.GET_STRIPE_INVOICES_FAILURE, error });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

function* checkStripeUnsuscribeSaga() {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    yield call(checkStripeUnsuscribeEndpoint, profile);
    yield put({ type: actions.STRIPE_CHECK_UNSUSCRIBE_SUCCESS });
    yield call(getStripeDataSaga);
  } catch (e) {
    yield put({ type: actions.STRIPE_CHECK_UNSUSCRIBE_FAILURE, e });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

export default function* paymentsSaga() {
  yield takeLatest(actions.GET_STRIPE_DATA_REQUEST, getStripeDataSaga);
  yield takeLatest(actions.GET_STRIPE_INVOICES_REQUEST, getStripeInvoicesSaga);
  yield takeLatest(
    actions.STRIPE_CHECK_UNSUSCRIBE_REQUEST,
    checkStripeUnsuscribeSaga
  );
}
