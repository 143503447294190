import * as React from 'react';
import { Card, Col, Icon, List, Progress, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import store from 'store';
import { bindActionCreators } from 'redux';

import Page from '../../components/Page';

import InviteRadiologists from './components/InviteRadiologists';
import { ONBOARDING_FORM } from '../../utils/form-helpers';
import { ReduxState } from '../../schemas';

import './index.less';
import InviteColleagues from './components/InviteColleagues';
import AddFacilities from './components/AddFacilities';
import OrderHardware from './components/OrderHardware';
import WebUploader from './components/WebUploader';
import Branding from './components/Branding';
import AddPhysicians from './components/AddPhysicians';
import {
  addFacilitiesRequest,
  addPhysiciansRequest,
  inviteColleaguesRequest,
  inviteRadiologistsRequest,
  ONBOARDING_LOADING,
  updateOnboardingSecondOpinionSettingRequest
} from '../../store/actions';
import LoadingSpinner from '../../common/LoadingSpinner';
import {postNotificationToZapierAPICall} from '../../api/endpoints/zapier';
import { showError, showSuccess } from '../../store/helpers';
import OnboardingFinish from './components/OnboardingFinish';

const OnBoardingItem = ({
  title,
  time,
  isDone,
  isSelected,
  setSelectedId,
  itemId
}) => {
  const onClick = () => {
    if (itemId !== 1) {
      setSelectedId(itemId);
    }
  };

  if (isSelected) {
    return (
      <List.Item onClick={onClick} className='onboarding-item--active'>
        <div>{title}</div>
        <div style={{ fontSize: 14 }}>{time} Mins</div>
      </List.Item>
    );
  }

  if (isDone) {
    return (
      <List.Item onClick={onClick} className='onboarding-item--done'>
        <div>{title}</div>
        <Icon
          type='check-circle'
          theme='filled'
          style={{ color: '#49BC19', fontSize: 20 }}
        />
      </List.Item>
    );
  }

  return (
    <List.Item onClick={onClick} className='onboarding-item--waiting'>
      <div>{title}</div>
      <div style={{ fontSize: 14, color: '#979797' }}>{time} Mins</div>
    </List.Item>
  );
};

const Onboarding = ({
  onboardingForm,
  change,
  inviteRadiologists,
  inviteColleagues,
  addFacilities,
  addPhysicians,
  updateSecondOpinionSetting,
  setLoading,
  loading
}) => {
  const { Title, Text } = Typography;
  const history = useHistory();

  const data = onboardingForm && onboardingForm.onboardingSteps;
  const selectedId = onboardingForm && onboardingForm.selectedId;

  const setSelectedId = id => {
    change('selectedId', id);
  };

  React.useEffect(() => {
    // const remainingSteps =
    //   onboardingForm &&
    //   onboardingForm.onboardingSteps &&
    //   onboardingForm.onboardingSteps.filter(item => !item.isDone);
    // if (remainingSteps && remainingSteps.length > 0) {
    //   setSelectedId(remainingSteps[0].id);
    // } else if (data) {
    //   setSelectedId(data.length + 1);
    // }

    store.set('onboardingSteps', data);
    store.set('onboardingSelectedId', selectedId);
  }, [data, selectedId]);

  const styles = {
    title: { marginBottom: '5px' },
    subTitle: {
      color: '#9B9B9B'
    }
  };

  const updateOnboardingSteps = updatedOnboardingSteps => {
    change(`onboardingSteps.${[selectedId - 1]}.isDone`, true);
    setSelectedId(selectedId + 1);
  };

  const remainingTime =
    data &&
    data.reduce((total = 0, item) => {
      if (item.isDone) {
        return total + 0;
      }
      return total + item.time;
    }, 0);

  const totalTime =
    data &&
    data.reduce((total = 0, item) => {
      return total + item.time;
    }, 0);

  const onBack = () => {
    if (selectedId > 2) {
      setSelectedId(selectedId - 1);
    }
  };

  const onNext = () => {
    updateOnboardingSteps();
  };

  const onInviteRadiologists = () => {
    const values = onboardingForm.inviteRadiologists;
    if (values.length > 0) {
      inviteRadiologists(values);
    } else {
      onNext();
    }
  };

  const onInviteColleagues = () => {
    const values = onboardingForm.inviteColleagues;
    if (values.length > 0) {
      inviteColleagues(values);
    } else {
      onNext();
    }
  };

  const onAddFacilities = () => {
    const values = onboardingForm.addFacilities;
    if (values.length > 0) {
      addFacilities(values);
    } else {
      onNext();
    }
  };

  const onOrderHardware = async name => {
    const content = {
      plan: name,
      numberOfAlemBox: name === 'Alembox' ? 1 : 0,
      numberOfAlemHub: name === 'Alemhub' ? 1 : 0
    };
    setLoading(true);
    const isZapierOk = await postNotificationToZapierAPICall({ content });
    if (isZapierOk) {
      setLoading(false);
      onNext();
      showSuccess(
        'Your request completed successfully. Our implementation engineers will get in touch with you.'
      );
    } else {
      setLoading(false);
      onNext();
      showError(
        new Error(
          `Something went wrong.Please, reload the page and contact us if the problem persists.`
        )
      );
    }
  };

  const onAddPhysicians = () => {
    const values = onboardingForm.addPhysicians;
    if (values.length > 0) {
      addPhysicians(values);
    } else {
      onNext();
    }
  };

  const onActivateUploader = () => {
    onNext();
  };

  const onUpdateBranding = values => {
    updateSecondOpinionSetting(values);
  };

  const onFinish = () => {
    history.push('/');
  };

  return (
    <Page>
      <Row gutter={[24, 24]} type='flex'>
        <Col xs={24} md={9}>
          <Row gutter={[0, 24]}>
            <Card style={{ minHeight: '90vh' }}>
              <Row gutter={[16, 24]} type='flex' align='middle'>
                <Col>
                  <Progress
                    type='circle'
                    percent={((totalTime - remainingTime) / totalTime) * 100}
                    width={100}
                  />
                </Col>
                <Col>
                  <Title level={4} style={styles.title}>
                    Get Started with AlemHealth Connect
                  </Title>
                  <Text style={styles.subTitle}>
                    {JSON.stringify(remainingTime)} minutes to go
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <List
                  size='large'
                  dataSource={data}
                  renderItem={item => (
                    <OnBoardingItem
                      setSelectedId={setSelectedId}
                      itemId={item.id}
                      title={item.title}
                      time={item.time}
                      isDone={item.isDone}
                      isSelected={item.id === selectedId}
                    />
                  )}
                />
              </Row>
            </Card>
          </Row>
        </Col>
        <Col xs={24} md={15}>
          <Row gutter={[0, 24]}>
            <Card style={{ minHeight: '90vh' }}>
              {loading ? (
                <LoadingSpinner
                  size='large'
                  rootStyle={{
                    position: 'absolute',
                    zIndex: 1,
                    background: 'rgba(255,255,255,0.8)',
                    width: '100%',
                    left: 0,
                    top: 0
                  }}
                />
              ) : null}
              {selectedId === 2 ? (
                <InviteRadiologists
                  data={onboardingForm.inviteRadiologists}
                  change={change}
                  onSubmit={onInviteRadiologists}
                  onBack={onBack}
                />
              ) : null}
              {selectedId === 3 ? (
                <InviteColleagues
                  data={onboardingForm.inviteColleagues}
                  change={change}
                  onSubmit={onInviteColleagues}
                  onBack={onBack}
                />
              ) : null}
              {selectedId === 4 ? (
                <AddFacilities
                  data={onboardingForm.addFacilities}
                  change={change}
                  onSubmit={onAddFacilities}
                  onBack={onBack}
                />
              ) : null}
              {selectedId === 5 ? (
                <OrderHardware
                  data={onboardingForm.addFacilities}
                  change={change}
                  onSubmit={onOrderHardware}
                  onBack={onBack}
                  onSkip={onNext}
                />
              ) : null}
              {selectedId === 6 ? (
                <WebUploader
                  change={change}
                  onSubmit={onActivateUploader}
                  onBack={onBack}
                  onSkip={onNext}
                />
              ) : null}
              {selectedId === 7 ? (
                <Branding
                  change={change}
                  onSubmit={onUpdateBranding}
                  onBack={onBack}
                  onSkip={onNext}
                />
              ) : null}
              {selectedId === 8 ? (
                <AddPhysicians
                  data={onboardingForm.addPhysicians}
                  change={change}
                  onSubmit={onAddPhysicians}
                  onBack={onBack}
                  onSkip={onNext}
                />
              ) : null}

              {selectedId === 9 ? (
                <OnboardingFinish
                  change={change}
                  onSubmit={onFinish}
                  onBack={onBack}
                  onSkip={onNext}
                />
              ) : null}
            </Card>
          </Row>
        </Col>
      </Row>
    </Page>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    onboardingForm: getFormValues(ONBOARDING_FORM)(state) || {},
    loading: state.loading.onboarding,
    initialValues: {
      inviteRadiologists: [],
      radiologist: {
        specialty: [],
        userType: 'Radiologist',
        groupFacilities: []
      },
      colleague: {},
      inviteColleagues: [],
      addFacilities: [],
      facility: {},
      addPhysicians: [],
      physician: {},
      selectedId: store.get('onboardingSelectedId') || 2,
      onboardingSteps: store.get('onboardingSteps') || [
        {
          id: 1,
          title: 'Sign Up for AlemHealth Connect',
          time: 3,
          isDone: true
        },
        { id: 2, title: 'Invite Radiologists', time: 3, isDone: false },
        { id: 3, title: 'Invite Colleagues', time: 3, isDone: false },
        {
          id: 4,
          title: 'Add Facilities to Report For',
          time: 3,
          isDone: false
        },
        { id: 5, title: 'Order AlemHealth Hardware', time: 3, isDone: false },
        { id: 6, title: 'Activate Web Uploader', time: 3, isDone: false },
        { id: 7, title: 'Set Your Branding', time: 3, isDone: false },
        { id: 8, title: 'Add Referring Physicians', time: 3, isDone: false }
      ]
    }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inviteRadiologists: inviteRadiologistsRequest,
      inviteColleagues: inviteColleaguesRequest,
      addFacilities: addFacilitiesRequest,
      addPhysicians: addPhysiciansRequest,
      updateSecondOpinionSetting: updateOnboardingSecondOpinionSettingRequest,
      setLoading: loading => ({ type: ONBOARDING_LOADING, loading })
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: ONBOARDING_FORM
  })(Onboarding)
);
