import * as React from 'react';
import { connect } from 'react-redux';

import {
  getAllPhysiciansAction,
  getPhysicianByIdAction,
  deletePhysicianAction
} from '../../../store/actions';

import Table from '../../../components/Table';
import ActionLink from '../../../components/ActionLink';
import TableMenu from './TableMenu';
import ViewPhysician from './ViewPhysician';
import { confirmDeletion } from '../../../components/confirmations';

export default connect(
  state => ({
    data: state.physicians.array,
    loading: state.loading.getPhysicians,
    modifications: state.physicians.modifications
  }),
  {
    getPhysicians: getAllPhysiciansAction,
    getPhysician: getPhysicianByIdAction,
    deletePhysician: deletePhysicianAction
  }
)(
  ({
    data,
    loading,
    modifications,
    getPhysicians,
    getPhysician,
    deletePhysician
  }) => {
    const [showViewPhysician, setShowViewPhysician] = React.useState(false);
    const [editVisible, setEditVisible] = React.useState(false);
    const openEdit = () => setEditVisible(true);
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) =>
          a.name
            .toString()
            .toLowerCase()
            .localeCompare(b.name.toString().toLowerCase()),
        columnSearch: true,
        align: 'left'
      },
      {
        title: 'Facility',
        dataIndex: 'primaryFacility',
        sorter: (a, b) =>
          (a.primaryFacility && a.primaryFacility.name
            ? a.primaryFacility.name
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.primaryFacility && b.primaryFacility.name
                ? b.primaryFacility.name
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.primaryFacility && record.primaryFacility.name
            ? record.primaryFacility.name
            : '-',
        align: 'left'
      },
      {
        title: 'Specialty',
        dataIndex: 'specialty',
        sorter: (a, b) =>
          (a.specialty && a.specialty.length > 0 && a.specialty[0].name
            ? a.specialty[0].name
            : '-'
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.specialty && b.specialty.length > 0 && b.specialty[0].name
                ? b.specialty[0].name
                : '-'
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.specialty &&
          record.specialty.length > 0 &&
          record.specialty[0].name
            ? record.specialty[0].name
            : '-',
        align: 'left'
      },
      {
        title: '',
        key: 'view',
        render: (text, record) => (
          <React.Fragment>
            <ActionLink
              click={() => setShowViewPhysician(record)}
              text='View'
            />
          </React.Fragment>
        )
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <TableMenu
            getAction={getPhysician}
            deleteAction={deletePhysician}
            record={record}
            editVisible={editVisible}
            isInternal
            hideEdit={() => {
              setShowViewPhysician(false);
              setEditVisible(false);
            }}
            showEdit={() => setEditVisible(true)}
          />
        )
      }
    ];
    const rowKey = record => record.guid;

    return (
      <>
        <Table
          columns={columns}
          data={data}
          modifications={modifications}
          rowKey={rowKey}
          loading={loading}
          getAction={getPhysicians}
        />
        {showViewPhysician && (
          <ViewPhysician
            data={showViewPhysician}
            handleCancel={() => {
              setShowViewPhysician(false);
              setEditVisible(false);
            }}
            handleDelete={() =>
              confirmDeletion(
                deletePhysician,
                showViewPhysician.guid,
                showViewPhysician.name,
                'referring physician'
              )
            }
            handleEdit={openEdit}
            visible={showViewPhysician}
          />
        )}
      </>
    );
  }
);
