import React, { useEffect } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
// import { Menu } from 'antd';
// import AddInternalFacility from './components/Edition/AddInternalFacility';
// import AddExternalFacility from './components/Edition/AddExternalFacility';

import { PlusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSVLink } from 'react-csv';
import './index.less';
// import TableInternalFacilities from './components/Table/TableInternalFacilities';
// import TableExternalFacilities from './components/Table/TableExternalFacilities';
import ReferringFacilitiesChart from './components/Chart/ReferringFacilitiesChart';
import ReferringFacilitiesTable from './components/Table/ReferringFacilitiesTable';
import { getAllExternalPhysiciansAction } from '../../../store/actions';
import AddReferringFacilities from './components/AddReferringFacilities';
// import ReferringFacilitiesViewTable from './components/Table/ReferringFacilitiesViewTable';

// const { TabPane } = Tabs;

const mapStateToProps = state => {
  return {
    chartData: state.referringFacilities.chart.chartData,
    tableData: state.referringFacilities.chart.tableData
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllExternalPhysicians: getAllExternalPhysiciansAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => {
  const [addNewVisible, setAddNewVisible] = React.useState(false);
  const [selectedTab] = React.useState('1');
  const openAdd = () => setAddNewVisible(true);
  const closeAdd = () => setAddNewVisible(false);

  const csvHeaders = [
    { label: 'Date', key: 'date' },
    { label: 'Facilities', key: 'referrerName' },
    { label: 'Category', key: 'facilityType' },
    { label: 'Referrals', key: 'value' }
  ];

  useEffect(() => {
    props.getAllExternalPhysicians();
  }, []);

  return (
    <div className='facilities-index'>
      <AddReferringFacilities
        selectedTab={selectedTab}
        visible={addNewVisible}
        close={closeAdd}
      />
      <Row
        type='flex'
        justify='end'
        gutter={12}
        className='page-cta-wrapper physicians-header'
      >
        <Col>
          <CSVLink
            headers={csvHeaders}
            data={
              props.chartData && props.chartData.length ? props.chartData : []
            }
            filename={`referring_facilities_${
              new Date().toISOString().split('T')[0]
            }`}
          >
            <Tooltip placement='left' title='Download CSV'>
              <Button className='add-physician-btn' type='primary'>
                Download CSV
              </Button>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>
      <ReferringFacilitiesChart />
      <Row
        type='flex'
        justify='end'
        gutter={12}
        className='page-cta-wrapper physicians-header'
      >
        <Col>
          <Button
            className='add-physician-btn'
            type='primary'
            onClick={openAdd}
            ghost
          >
            <PlusCircleOutlined /> Add Referring Facility
          </Button>
        </Col>
      </Row>
      <ReferringFacilitiesTable />
      {/* <ReferringFacilitiesViewTable /> */}
    </div>
  );
});
