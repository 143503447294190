// @flow

import * as React from 'react';
import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import type { ReduxState } from '../../../../schemas';
import { stripePlanSpecifications } from '../../../../utils/stripe';
import PlanDescription from './PlanDescription';
import PlanPricingCard from './PlanPricingCard';

import './index.less';

export default () => {
  const currency = useSelector(
    (state: ReduxState) => state.payments.data.currency
  );
  const email = useSelector((state: ReduxState) => state.payments.data.email);
  const id = useSelector((state: ReduxState) => state.payments.data.id);
  const facilitiesLoading = useSelector(
    (state: ReduxState) => state.loading.facilities
  );
  const facilities = useSelector(
    (state: ReduxState) => state.facilities.internalArray
  );
  if (facilitiesLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }
  return (
    <div className='plans-pricing'>
      <Row gutter={24} className='plans-pricing-list'>
        <Col
          className='plans-pricing-list-item plans-pricing-list-hardware'
          xs={24}
        >
          <PlanDescription />
          <Row type='flex' gutter={[24, 24]}>
            {stripePlanSpecifications(facilities.length, currency).map(
              (s, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={`${s.id}-${i}`} span={8}>
                  <PlanPricingCard {...{ ...s, id, email }} />
                </Col>
              )
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
