import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Table from '../../../components/Table';
import ViewInternalRadiologist from './ViewInternalRadiologist';
import {
  deleteInternalRadiologistAction,
  getInternalRadiologistByIdAction,
  getInternalRadiologistsAction
} from '../../../store/actions';
import { RAD_SPECIALTY } from '../../../utils/filters';
import TableMenu from './TableMenu';

import type { ReduxState } from '../../../schemas';
import { adaptUserData } from '../../../utils';

export default connect(
  (state: ReduxState) => ({
    data: state.internalRadiologists.array,
    total: state.internalRadiologists.total,
    loading: state.loading.internalRads,
    modifications: state.users.modifications,
    currentSearch: state.internalRadiologists.currentSearch
  }),
  {
    getRadiologistsAction: getInternalRadiologistsAction,
    getAction: getInternalRadiologistByIdAction,
    deleteAction: deleteInternalRadiologistAction
  }
)(
  ({
    data,
    total,
    loading,
    modifications,
    getRadiologistsAction,
    getAction,
    deleteAction
  }) => {
    const [modificationsState, setModificationsState] = React.useState(
      modifications
    );
    /* eslint-disable-next-line */
    const [adaptedData, setAdaptedData] = React.useState(adaptUserData(data));

    React.useEffect(() => {
      if (modificationsState > modifications) {
        setModificationsState(modifications);
        setAdaptedData(adaptUserData(data));
      }
    }, [data, modifications]);

    const columns = [
      {
        title: t('manage_radiologists.table.name'),
        dataIndex: 'userMetadata.name',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        columnSearch: true
      },
      {
        title: t('manage_radiologists.table.specialty'),
        dataIndex: 'specialty',
        filters: RAD_SPECIALTY,
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.specialty
            ? a.userMetadata.specialty
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.specialty
                ? b.userMetadata.specialty
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        onFilter: (value, record) =>
          (record.userMetadata.specialty ? record.userMetadata.specialty : '')
            .toString()
            .includes(value),
        render: (text, record) =>
          record.userMetadata.specialty
            ? record.userMetadata.specialty.join(', ')
            : '—'
      },
      {
        title: t('manage_radiologists.table.email_adress'),
        dataIndex: 'email',
        columnSearch: true
      },
      {
        title: '',
        dataIndex: 'details',
        key: 'details',
        render: (text, record) => (
          <ViewInternalRadiologist internalRadiologistId={record.id} />
        )
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <TableMenu
            record={record}
            getAction={getAction}
            deleteAction={deleteAction}
            recordName='internal radiologist'
          />
        )
      }
    ];
    const rowKey = record => record.id;

    return (
      <Table
        columns={columns}
        data={data}
        total={total}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getRadiologistsAction}
      />
    );
  }
);
