/* eslint-disable */
// @flow

import React, { Component } from 'react';
import { Menu, Icon, Row, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import { isEqual } from '../../utils';
import type {
  Group as GroupSchema,
  AccountSettings,
  User,
  NotificationsSettings,
  ReduxState
} from '../../schemas';

import {
  loadSettingsAccountForm,
  loadSettingsGroupForm,
  loadSettingsNotificationsForm,
  resetUserPasswordAction,
  updateAccountSettings,
  updateNotificationsSettings,
  getGroupRequest,
  updateGroupRequest,
  updatePrint
} from '../../store/actions';

import Notifications from './Notifications';
import Account from './Account';
import Group from './Group';

import './index.less';
import Print from './Print';

type Props = {
  profile: User,
  group: GroupSchema,
  // eslint-disable-next-line react/no-unused-prop-types
  notificationSettings: NotificationsSettings,
  loadSettingsGroupFormAction: any => void,
  loadSettingsAccountFormAction: any => void,
  loadSettingsNotificationsFormAction: any => void,
  updateAccountSettingsAction: User => void,
  updateNotificationsSettingsAction: NotificationsSettings => void,
  resetPasswordAction: string => void,
  getGroupAction: () => void,
  updateGroupAction: GroupSchema => void,
  updateLetterheadAction: ({ letterhead: string }) => void
};

type State = {
  profile: {
    name: string,
    phone: string,
    email: string,
    title: string,
    facility: string,
    userType: string,
    specialty: Array<string>
  },
  group: GroupSchema,
  notifications: NotificationsSettings,
  current: string,
  firstLoad: boolean
};

export default connect(
  (state: ReduxState) => ({
    profile: state.profile,
    group: state.group,
    notificationSettings: state.profile.notificationSettings
  }),
  {
    loadSettingsGroupFormAction: loadSettingsGroupForm,
    loadSettingsAccountFormAction: loadSettingsAccountForm,
    loadSettingsNotificationsFormAction: loadSettingsNotificationsForm,
    updateAccountSettingsAction: updateAccountSettings,
    updateNotificationsSettingsAction: updateNotificationsSettings,
    resetPasswordAction: resetUserPasswordAction,
    getGroupAction: getGroupRequest,
    updateGroupAction: updateGroupRequest,
    updateLetterheadAction: updatePrint
  }
)(
  class IndexSettings extends Component<Props, State> {
    state = {
      profile: {
        name: '',
        phone: '',
        email: '',
        title: '',
        facility: '',
        userType: '',
        specialty: []
      },
      group: {
        name: '',
        location: '',
        officeNumber: '',
        emailAddress: '',
        website: '',
        logo: '',
        hasLetterhead: ''
      },
      notifications: {
        newStudy: [],
        urgentStudy: [],
        SLANearing: [],
        reportFinalised: [],
        secondOpinion: [],
        codeBlue: []
      },
      current: '0', // Current panel, 0 == loading
      firstLoad: true
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      if (
        nextProps.profile.name !== prevState.profile.name ||
        nextProps.profile.userMetadata.phoneNumber !==
          prevState.profile.phone ||
        nextProps.profile.email !== prevState.profile.email ||
        nextProps.profile.userMetadata.title !== prevState.profile.title ||
        nextProps.profile.userMetadata.homeFacility !==
          prevState.profile.facility ||
        nextProps.profile.userMetadata.specialty !==
          prevState.profile.specialty ||
        nextProps.profile.userMetadata.userType !== prevState.profile.userType
      ) {
        return {
          profile: {
            name: nextProps.profile.name,
            phone: nextProps.profile.userMetadata.phoneNumber,
            email: nextProps.profile.email,
            title: nextProps.profile.userMetadata.title,
            credentials: nextProps.profile.userMetadata.credentials,
            facility: nextProps.profile.userMetadata.homeFacility,
            specialty: nextProps.profile.userMetadata.specialty,
            userType: nextProps.profile.userMetadata.userType
          }
        };
      }
      if (!isEqual(nextProps.notificationSettings, prevState.notifications)) {
        return { notifications: nextProps.notificationSettings };
      }
      if (!isEqual(nextProps.group, prevState.group)) {
        return { group: nextProps.group };
      }
      return null;
    }

    componentDidMount() {
      const {
        profile,
        notificationSettings,
        group,
        getGroupAction
      } = this.props;
      getGroupAction();
      this.setState({
        profile: {
          name: profile.name,
          phone: profile.userMetadata.phoneNumber,
          email: profile.email,
          title: profile.userMetadata.title,
          facility: profile.userMetadata.homeFacility,
          credentials: profile.userMetadata.credentials,
          userType: profile.userMetadata.userType,
          specialty: profile.userMetadata.specialty
        },
        notifications: notificationSettings,
        group
      });
      this.updateAccountSettings();
      this.updateNotificationSettings();
      this.updateGroupSettings();
      this.setState({ current: '1' });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      const { notifications, profile, group } = this.state;
      if (!isEqual(prevState.profile, profile)) {
        this.updateAccountSettings();
      }
      if (!isEqual(prevState.notifications, notifications)) {
        this.updateNotificationSettings();
      }
      if (!isEqual(prevState.group, group)) {
        this.updateGroupSettings();
      }
    }

    updateAccountSettings = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.loadSettingsAccountFormAction(this.state.profile);
    };

    updateGroupSettings = () =>
      // eslint-disable-next-line react/destructuring-assignment
      this.props.loadSettingsGroupFormAction(this.state.group);

    updateNotificationSettings = () => {
      const { notifications } = this.state;
      const { loadSettingsNotificationsFormAction } = this.props;
      const data = {};
      Object.keys(notifications).forEach(key => {
        data[`${key}|alemHealth`] = notifications[key][0] === '1';
        data[`${key}|push`] = notifications[key][1] === '1';
        data[`${key}|email`] = notifications[key][2] === '1';
      });
      loadSettingsNotificationsFormAction(data);
    };

    handleClick = e => this.setState({ current: e.key });

    handleResetPassword = () => {
      const { resetPasswordAction } = this.props;
      const {
        profile: { email }
      } = this.state;
      resetPasswordAction(email);
    };

    handleAccountSave = async (values: AccountSettings) =>
      this.props.updateAccountSettingsAction(values);

    handleNotificationsSave = async values => {
      const { updateNotificationsSettingsAction } = this.props;
      const data = {};
      Object.keys(values).forEach(key => {
        const k = key.split('|')[0];
        if (!data[k]) {
          data[k] = [];
        }
        data[k].push(values[key]);
      });
      updateNotificationsSettingsAction(data);
    };

    handleGroupSave = async (values: GroupSchema) =>
      this.props.updateGroupAction(values);

    handlePrintSave = async (values: { letterhead: string }) =>
      this.props.updateLetterheadAction(values);

    render() {
      const { profile } = this.props;
      const {
        current,
        group,
        profile: { userType }
      } = this.state;
      let content;
      switch (current) {
        case '0':
          content = (
            <div style={{ textAlign: 'center' }}>
              <Spin size='large' />
            </div>
          );
          break;
        case '1':
          content = (
            <Account
              onSubmit={this.handleAccountSave}
              handleResetPassword={this.handleResetPassword}
              userType={userType ? userType : ''}
            />
          );
          break;
        case '2':
          content = (
            <Group
              onSubmit={values =>
                this.handleGroupSave({ ...values, logo: this.state.group.logo })
              }
              groupId={profile.appMetadata.affiliatedGroup}
              group={group}
            />
          );
          break;
        case '3':
          content = <Print onSubmit={this.handlePrintSave} group={group} />;
          break;
        default:
      }
      return (
        <div className='settings-menu'>
          <Row gutter={32} type='flex'>
            <Col span={6}>
              <Menu
                mode='inline'
                defaultSelectedKeys={['1']}
                onClick={this.handleClick}
              >
                <Menu.Item key='1'>
                  <Icon type='user' />
                  <span className='nav-text'>Account</span>
                </Menu.Item>
                <Menu.Item key='2'>
                  <Icon type='home' />
                  <span className='nav-text'>Group</span>
                </Menu.Item>
                <Menu.Item key='3'>
                  <Icon type='printer' />
                  <span className='nav-text'>Print Settings</span>
                </Menu.Item>
                {/*
                  <Menu.Item key='2'>
                    <Icon type='bell' />
                    <span className='nav-text'>Notifications</span>
                  </Menu.Item>
                */}
              </Menu>
            </Col>
            <Col span={18}>{content}</Col>
          </Row>
        </div>
      );
    }
  }
);
