import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin, Row, Col, Button } from 'antd';
import { confirmDeletion } from '../../../components/confirmations';
import {
  deleteExternalRadiologistAction,
  getExternalRadiologistByIdAction
} from '../../../store/actions';
import ActionLink from '../../../components/ActionLink';

import type { User, Country, ReduxState } from '../../../schemas';

type Props = {
  data: User,
  externalRadiologistId: string,
  loading: boolean,
  getAction: string => void,
  deleteAction: (string, string) => void,
  countries: Array<Country>
};

type State = {
  visible: boolean
};

export default connect(
  (state: ReduxState) => ({
    data: state.externalRadiologists.current,
    loading: state.loading.externalRad,
    countries: state.countryList
  }),
  {
    getAction: getExternalRadiologistByIdAction,
    deleteAction: deleteExternalRadiologistAction
  }
)(
  class ViewExternalRadiologist extends React.Component<Props, State> {
    state = {
      visible: false,
      groupName: ''
    };

    showModal = () => {
      const { getAction, externalRadiologistId } = this.props;
      getAction(externalRadiologistId);
      this.setState({ visible: true });
    };

    handleCancel = () => this.setState({ visible: false });

    handleDelete = () => {
      const {
        deleteAction,
        data: { id, name }
      } = this.props;
      confirmDeletion(deleteAction, id, name, 'external radiologist');
    };

    render() {
      const { data, loading, countries } = this.props;
      const { name, email, userMetadata } = data;
      const { specialty, phoneNumber, prefix } = userMetadata || {};
      let country = '';
      if (prefix) {
        const countryObjects = countries.filter(c => c.extension === prefix);
        if (countryObjects.length > 0) {
          country = countryObjects[0].name;
        }
      }
      const studyAccess = 'All studies'; // TODO: what should we put instead of studyAccess ?
      const { visible } = this.state;
      if (Object.entries(data).length === 0 || loading) {
        return (
          <div>
            <ActionLink click={this.showModal} text='View' />
            <Modal
              visible={visible}
              title='External Radiologist Details'
              okText='Edit'
              footer={null}
              onCancel={this.handleCancel}
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </div>
        );
      }
      return (
        <div>
          <ActionLink click={this.showModal} text='View' />
          <Modal
            title='External Radiologist Details'
            okText='Edit'
            visible={visible}
            onCancel={this.handleCancel}
            destroyOnClose
            footer={
              <Row type='flex' justify='space-around' align='middle'>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Button
                    className='custom-link'
                    aling='left'
                    onClick={this.handleDelete}
                  >
                    Delete Radiologist
                  </Button>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  <Button onClick={this.handleCancel}>Close</Button>
                </Col>
              </Row>
            }
          >
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>Provider Name</p>
                <p style={{ color: 'black' }}>{name}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>Study Access</p>
                <p style={{ color: 'black' }}>{studyAccess}</p>
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>Country</p>
                <p style={{ color: 'black' }}>{country}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>Mobile Number</p>
                <p style={{ color: 'black' }}>{phoneNumber}</p>
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>Email</p>
                <p style={{ color: 'black' }}>{email}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>Specialty</p>
                <p style={{ color: 'black' }}>{specialty}</p>
              </Col>
            </Row>
          </Modal>
        </div>
      );
    }
  }
);
