// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Table from '../../components/Table';
import ViewUser from './ViewUser';
import TableMenu from './partials/TableMenu';
import {
  deleteUserAction,
  getInternalFacilitiesAction,
  getUserByIdAction,
  getUsersAction,
  resetUserPasswordAction
} from '../../store/actions';
import { YES_NO, USER_TYPE } from '../../utils/filters';

import type { Facility, ReduxState, User } from '../../schemas';
import { adaptUserData } from '../../utils';

type Props = {
  data: Array<User>,
  total: number,
  loading: boolean,
  modifications: number,
  facilities: Array<Facility>,
  getAction: () => void,
  getByIdAction: string => void,
  deleteAction: string => void,
  resetPassword: string => void,
  getFacilities: () => void
};

export default connect(
  (state: ReduxState) => ({
    data: state.users.array,
    total: state.users.total,
    loading: state.loading.getUsers,
    modifications: state.users.modifications,
    facilities: state.facilities.internalArray
  }),
  {
    getAction: getUsersAction,
    getByIdAction: getUserByIdAction,
    deleteAction: deleteUserAction,
    resetPassword: resetUserPasswordAction,
    getFacilities: getInternalFacilitiesAction
  }
)(
  ({
    data,
    total,
    loading,
    modifications,
    getAction,
    deleteAction,
    resetPassword,
    getByIdAction,
    facilities,
    getFacilities
  }: Props) => {
    const [homeFacilities, setHomeFacilities] = useState(
      facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
    );

    React.useEffect(() => {
      getFacilities();
    }, []);
    React.useEffect(() => {
      if (homeFacilities.length !== facilities.length) {
        setHomeFacilities(
          facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
        );
      }
    }, [facilities, homeFacilities]);

    const [modificationsState, setModificationsState] = React.useState(
      modifications
    );
    /* eslint-disable-next-line */
    const [adaptedData, setAdaptedData] = React.useState(adaptUserData(data));

    React.useEffect(() => {
      if (modificationsState > modifications) {
        setModificationsState(modifications);
        setAdaptedData(adaptUserData(data));
      }
    }, [data, modifications]);

    const columns = [
      {
        title: t('user_administration.field.name'),
        dataIndex: 'userMetadata.name',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        columnSearch: true,
        render: (text, record) => 'hola'
      },
      {
        title: t('user_administration.field.role'),
        dataIndex: 'userType',
        filters: USER_TYPE,
        onFilter: (value, record) => {
          return record.userMetadata.userType.toString().includes(value);
        },
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.userType
            ? a.userMetadata.userType
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.userType
                ? b.userMetadata.userType
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) => {
          return record.userMetadata.userType
            ? record.userMetadata.userType
            : '—';
        }
      },
      {
        title: t('user_administration.field.home_facility'),
        dataIndex: 'homeFacility',
        filters: homeFacilities,
        onFilter: (value, record) => {
          return (record.userMetadata && record.userMetadata.homeFacility
            ? record.userMetadata.homeFacility
            : ''
          )
            .toString()
            .includes(value);
        },
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.homeFacility
            ? a.userMetadata.homeFacility
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.homeFacility
                ? b.userMetadata.homeFacility
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.userMetadata.homeFacility
            ? record.userMetadata.homeFacility
            : '—'
      },
      {
        // TODO: Update when status payload exist
        title: t('user_administration.field.status'),
        dataIndex: 'status',
        render: (text, record) => 'Active'
      },
      {
        title: t('user_administration.field.admin'),
        dataIndex: 'admin',
        filters: YES_NO,
        onFilter: (value, record) => {
          return 'appMetadata' in record
            ? record.appMetadata.admin.toString().includes(value)
            : '';
        },
        sorter: (a, b) =>
          (a.appMetadata && a.appMetadata.admin ? a.appMetadata.admin : '')
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.appMetadata && b.appMetadata.admin ? b.appMetadata.admin : '')
                .toString()
                .toLowerCase()
            ),
        render: (_text, record) => {
          if (record.appMetadata && record.appMetadata.admin) {
            let text = record.appMetadata.admin === '1' ? 'Yes' : 'No';
            text += record.appMetadata.main === '1' ? ' (Main)' : '';
            return text;
          }
          return '—';
        }
      },
      {
        title: '',
        dataIndex: 'details',
        key: 'details',
        render: (text, record) => <ViewUser userId={record.id} />
      },
      {
        title: '',
        key: 'action',
        width: 100,
        render: (text, record) => (
          <TableMenu
            record={record}
            getAction={getByIdAction}
            deleteAction={deleteAction}
            resetPassword={resetPassword}
          />
        )
      }
    ];
    const rowKey = user => user.id;
    return (
      <Table
        columns={columns}
        total={total}
        data={data}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getAction}
      />
    );
  }
);
