import React from 'react';
import { connect } from 'react-redux';

import { t } from 'i18next';
import Table from '../../../components/Table';
import ViewExternalRadiologist from './ViewExternalRadiologist';
import {
  deleteExternalRadiologistAction,
  getExternalRadiologistByIdAction,
  getExternalRadiologistsAction
} from '../../../store/actions';
import { RAD_SPECIALTY } from '../../../utils/filters';
import TableMenu from '../GeneralTableMenu';

import type { ReduxState, User } from '../../../schemas';
import { adaptUserData } from '../../../utils';

type Props = {
  data: Array<User>,
  total: number,
  loading: boolean,
  modifications: number,
  getRadiologistAction: () => void,
  getAction: string => void,
  deleteAction: string => void
};

export default connect(
  (state: ReduxState) => ({
    data: state.externalRadiologists.array,
    total: state.externalRadiologists.total,
    loading: state.loading.externalRads,
    modifications: state.externalRadiologists.modifications // TODO: CHANGE THIS
  }),
  {
    getRadiologistsAction: getExternalRadiologistsAction,
    getAction: getExternalRadiologistByIdAction,
    deleteAction: deleteExternalRadiologistAction
  }
)(
  ({
    data,
    total,
    loading,
    modifications,
    getRadiologistsAction,
    getAction,
    deleteAction
  }: Props) => {
    const [modificationsState, setModificationsState] = React.useState(
      modifications
    );
    /* eslint-disable-next-line */
    const [adaptedData, setAdaptedData] = React.useState(adaptUserData(data));

    React.useEffect(() => {
      if (modificationsState > modifications) {
        setModificationsState(modifications);
        setAdaptedData(adaptUserData(data));
      }
    }, [data, modifications]);

    const columns = [
      {
        title: t('manage_radiologists.table.name'),
        dataIndex: 'name',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        columnSearch: true
      },
      {
        title: t('manage_radiologists.table.specialty'),
        dataIndex: 'specialty',
        filters: RAD_SPECIALTY,
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.specialty
            ? a.userMetadata.specialty
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.specialty
                ? b.userMetadata.specialty
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        onFilter: (value, record) =>
          (record.userMetadata.specialty ? record.userMetadata.specialty : '')
            .toString()
            .includes(value),
        render: (text, record) =>
          record.userMetadata.specialty
            ? record.userMetadata.specialty.join(', ')
            : '—'
      },
      {
        title: t('manage_radiologists.table.email_adress'),
        dataIndex: 'email',
        columnSearch: true
      },
      {
        title: '',
        dataIndex: 'details',
        key: 'details',
        render: (text, record) => (
          <ViewExternalRadiologist externalRadiologistId={record.id} />
        )
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <TableMenu
            record={record}
            getAction={getAction}
            deleteAction={deleteAction}
            recordName='external radiologist'
          />
        )
      }
    ];
    const rowKey = record => record.id;
    return (
      <Table
        columns={columns}
        data={data}
        total={total}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getRadiologistsAction}
      />
    );
  }
);
