import React, { Component, Fragment } from 'react';
import { Menu, Dropdown } from 'antd';
import { t } from 'i18next';
import {
  confirmDeletion,
  confirmResetPassword
} from '../../../components/confirmations';
import EditUser from '../EditUser';
import type { User } from '../../../schemas';

const { Item } = Menu;

type Props = {
  record: User,
  getAction: () => void,
  deleteAction: (string, string) => void,
  resetPassword: string => void
};

type State = {
  editUser: boolean
};

export default class UserTableActions extends Component<Props, State> {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    editUser: false
  };

  handleCancelEdit = () => {
    this.setState({ editUser: false });
  };

  handleMenuClick = e => {
    const { deleteAction, resetPassword, getAction, record } = this.props;
    const { props } = e.item;
    switch (e.key) {
      case '1':
        getAction(record.id);
        this.setState({ editUser: true });
        break;
      case '2':
        confirmDeletion(deleteAction, props.id, props.name, 'user');
        break;
      case '3':
        confirmResetPassword(resetPassword, props.email);
        break;
      case '4':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { record } = this.props;
    const { editUser } = this.state;
    return (
      <Fragment>
        <EditUser visible={editUser} onCancel={this.handleCancelEdit} />
        <Dropdown
          overlay={
            <Menu onClick={this.handleMenuClick}>
              <Item key='1' id={record.id}>
                {t('dashboard.edit_user')}
              </Item>
              <Item
                key='2'
                id={record.id}
                name={record.name}
                disabled={record.appMetadata.main === '1'}
              >
                {t('user_administration.field.delete_user')}
              </Item>
              <Item key='3' email={record.email}>
                {t('button.reset_password')}
              </Item>
              <Item key='4' id={record.id}>
                {t('button.cancel')}
              </Item>
            </Menu>
          }
          trigger={['click']}
        >
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            Menu
          </a>
        </Dropdown>
      </Fragment>
    );
  }
}
