import React from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'antd';
import DateRangePickerField from '../../../../../../common/form-helpers/DateRangePickerField';
import { SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME } from '../../../../../../utils/form-helpers';
import InputSelectField from '../../../../../../common/form-helpers/InputSelectField';
import RadioGroupField from '../../../../../../common/form-helpers/RadioGroupField';
import {
  dateFilters,
  dateFilterStrings,
  dateOptions
} from '../../../../../../store/constants';
import { selectReportingRadiologistOptions } from '../../../../../../store/selectors';
import { getSourceReferringFacilitiesChartAction } from '../../../../../../store/actions';

function ReferringFacilitiesChartCardForm({
  radioDateOptions,
  reportingRadiologistOptions,
  physicianOptions,
  modalityOptions,
  getSourceReferringFacilitiesChart,
  changeField,
  addValue,
  forms
}) {
  const changeDateFilter = async event => {
    await changeField('selectedDate');
    if (event.target && event.target.value === dateFilters.TODAY) {
      await addValue('dateFilterType', dateFilterStrings.TODAY);
    } else {
      await addValue('dateFilterType', dateFilterStrings.THIS_WEEK);
    }
    getSourceReferringFacilitiesChart();
  };

  const changeDateRange = async values => {
    if (values && values.length === 2) {
      await changeField('dateFilter');
      await addValue('dateFilterType', dateFilterStrings.CUSTOM);
      getSourceReferringFacilitiesChart();
    }
  };

  const changeModality = async values => {
    await addValue('modality', values);
    getSourceReferringFacilitiesChart();
  };

  return (
    <Row
      id='referring-facilities-chart-card-form'
      type='flex'
      align='middle'
      justify='end'
      gutter={[16, 12]}
    >
      {/* <Col xs={24} sm={12} md={12} xl={4}>
        <Field
          name='referrerName'
          labelStyle={{ margin: 0 }}
          placeholder='Select Referrer'
          options={physicianOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col> */}
      <Col xs={24} sm={12} md={12} xl={4}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder='Select Modality'
          options={modalityOptions}
          width='100%'
          mode='multiple'
          allowClear
          component={InputSelectField}
          onChange={changeModality}
        />
      </Col>
      <Col xs={24} sm={12} md={12} xl={6}>
        <Field
          name='dateFilter'
          labelStyle={{ margin: 0 }}
          radioBtnType='outline'
          options={radioDateOptions}
          component={RadioGroupField}
          onChange={event => changeDateFilter(event)}
        />
      </Col>
      <Col xs={24} sm={12} md={12} xl={6}>
        <Field
          name='selectedDate'
          labelStyle={{ margin: 0 }}
          width='100%'
          component={DateRangePickerField}
          onChange={values => changeDateRange(values)}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = state => {
  return {
    radioDateOptions: [dateOptions[0], dateOptions[1]],
    reportingRadiologistOptions: selectReportingRadiologistOptions(state),
    initialValues: {
      dateFilter: dateFilters.THIS_WEEK,
      selectedDate: '',
      selectedReport: 'all',
      referrerName: '',
      modality: '',
      dateFilterType: dateFilterStrings.THIS_WEEK
    },
    forms: state.form[SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME]
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSourceReferringFacilitiesChart: getSourceReferringFacilitiesChartAction,
      changeField: fieldName =>
        change(SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME, fieldName, ''),
      addValue: (fieldName, value) =>
        change(SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME, fieldName, value)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME
  })(ReferringFacilitiesChartCardForm)
);
