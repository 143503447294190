import React, { useEffect } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Menu } from 'antd';
// import AddInternalFacility from './components/Edition/AddInternalFacility';
// import AddExternalFacility from './components/Edition/AddExternalFacility';

import { PlusCircleOutlined } from '@ant-design/icons';
import './index.less';
// import TableExternalFacilities from './components/Table/TableExternalFacilities';
import ReferringPhysiciansChart from './components/Chart/ReferringPhysiciansChart';
import ReferringPhysiciansTable from './components/Table/ReferringPhysiciansTable';
// import ReferringPhysiciansViewTable from './components/Table/ReferringPhysiciansViewTable';
import { getAllExternalPhysiciansAction } from '../../../store/actions';
import AddReferringPhysicians from './components/AddReferringPhysicians';

// const { TabPane } = Tabs;

const mapStateToProps = state => {
  return {
    chartData: state.referringPhysicians.chart.chartData,
    tableData: state.referringPhysicians.chart.tableData
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllExternalPhysicians: getAllExternalPhysiciansAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => {
  const [addNewVisible, setAddNewVisible] = React.useState(false);
  const [selectedTab] = React.useState('1');
  const openAdd = () => setAddNewVisible(true);
  const closeAdd = () => setAddNewVisible(false);

  const csvHeaders = [
    { label: 'Date', key: 'date' },
    { label: 'Physicians', key: 'referrerName' },
    { label: 'Referrals', key: 'value' }
  ];

  useEffect(() => {
    // props.getAllExternalPhysicians();
  }, []);

  return (
    <div className='facilities-index'>
      <AddReferringPhysicians
        selectedTab={selectedTab}
        visible={addNewVisible}
        close={closeAdd}
      />
      <Row
        type='flex'
        justify='end'
        gutter={12}
        className='page-cta-wrapper physicians-header'
      >
        <Col>
          <CSVLink
            headers={csvHeaders}
            data={
              props.chartData && props.chartData.length ? props.chartData : []
            }
            filename={`referring_physicians_${
              new Date().toISOString().split('T')[0]
            }`}
          >
            <Tooltip placement='left' title='Download CSV'>
              <Button className='add-physician-btn' type='primary'>
                Download CSV
              </Button>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>
      <ReferringPhysiciansChart />
      <Row
        type='flex'
        justify='end'
        gutter={12}
        className='page-cta-wrapper physicians-header'
      >
        <Col>
          <Button
            className='add-physician-btn'
            type='primary'
            onClick={openAdd}
            ghost
          >
            <PlusCircleOutlined /> Add Referring Physician
          </Button>
        </Col>
      </Row>
      <ReferringPhysiciansTable />
      {/* <ReferringPhysiciansViewTable /> */}
    </div>
  );
});
