// @flow

import React from 'react';
import { Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { confirmDeletion } from '../../components/confirmations';
import EditUserForm from './forms/EditUserForm';
import {
  editUserAction,
  getUserByIdAction,
  loadEditUserForm,
  deleteUserAction,
  getInternalFacilitiesAction
} from '../../store/actions';
import type { Facility, ReduxState, User } from '../../schemas';
import { editUserValuesToUser } from '../../api/transformers';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: Object,
  user: User,
  affiliatedGroup: string,
  visible: boolean,
  load: boolean,
  loading: boolean,
  loadingFacilities: boolean,
  onCancel: () => void,
  editAction: (User, string) => void,
  deleteAction: string => void,
  // eslint-disable-next-line react/no-unused-prop-types
  loadAction: string => void,
  loadFacilities: () => void,
  loadForm: any => void,
  profileId: string,
  facilities: Array<Facility>,
  initialValues: Object
};

type State = {
  user: User,
  currentRole: ?string
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.editUserForm,
    user: state.users.current,
    load: state.loading.getUserById,
    loading: state.loading.updateUser,
    loadingFacilities: state.loading.facilities,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    profileId: state.profile.id,
    facilities: state.facilities.internalArray,
    initialValues: state.initialForm.editUser
  }),
  {
    editAction: editUserAction,
    deleteAction: deleteUserAction,
    loadAction: getUserByIdAction,
    loadForm: loadEditUserForm,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class EditUser extends React.Component<Props, State> {
    state = {
      user: {},
      currentRole: undefined
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.user.id !== prevState.user.id) {
        return { user: nextProps.user };
      }
      return null;
    }

    componentDidMount() {
      const { user } = this.state;
      const { loadFacilities } = this.props;
      loadFacilities();
      if (Object.entries(user).length !== 0) {
        this.updateUser();
      }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      const {
        user: { id }
      } = this.state;
      if (prevState.user.id !== id) {
        this.updateUser();
      }
      if (prevProps.loading && !this.props.loading) {
        this.props.onCancel();
      }
    }

    updateUser = () => {
      const { user } = this.state;
      const { loadForm } = this.props;
      const form = {
        name: user.name,
        userType: user.userMetadata.userType,
        email: user.email,
        phoneNumber: user.userMetadata.phoneNumber,
        homeFacility: user.userMetadata.homeFacility,
        specialty: user.userMetadata.specialty,
        availableForAssignment: user.appMetadata.availableForAssignment || '1',
        allowed_to_delete_study:
          user.appMetadata.allowed_to_delete_study || '1',
        admin: user.appMetadata.admin,
        title: user.userMetadata.title,
        credentials: user.userMetadata.credentials
      };
      loadForm(form);
    };

    onChangeUserType = value => this.setState({ currentRole: value });

    handleSubmit = async values => {
      const { editAction, affiliatedGroup, user, facilities } = this.props;
      editAction(
        editUserValuesToUser(values, affiliatedGroup, facilities),
        user.id
      );
    };

    handleDelete = () => {
      const { user, deleteAction } = this.props;
      confirmDeletion(deleteAction, user.id, user.name, 'user');
    };

    render() {
      const {
        visible,
        onCancel,
        loading,
        user,
        load,
        loadingFacilities,
        profileId,
        facilities,
        initialValues
      } = this.props;
      if (Object.entries(user).length === 0 || load || loadingFacilities) {
        return (
          <div>
            <Modal
              visible={visible}
              title='Edit user'
              okText='Edit'
              footer={null}
              onCancel={onCancel}
              wrapClassName='lateral-modal'
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </div>
        );
      }
      let groupFacilities = [];
      if (user.appMetadata && user.appMetadata.approvedFacilities) {
        groupFacilities = facilities
          .filter((f: Facility) =>
            user.appMetadata.approvedFacilities.includes(f.guid)
          )
          .map((f: Facility) => f.name);
      }

      return (
        <div>
          <EditUserForm
            visible={visible}
            onCancel={onCancel}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
            onChangeUserType={this.onChangeUserType}
            loading={loading}
            user={user}
            isActiveUser={user.id === profileId}
            initialValues={{ ...initialValues, groupFacilities }}
          />
        </div>
      );
    }
  }
);
