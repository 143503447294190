// @flow

import store from 'store';

import { config, isEmpty } from '../utils';

import type { SSOData, User } from '../schemas';

export const getProfileFromLocalStorage = () => {
  return store.get('profile');
};

export const saveProfileToLocalStorage = (profile: User) => {
  store.set('profile', profile);
};

export const deleteProfileFromLocalStorage = () => {
  store.remove('profile');
};

export const saveSSODataToLocalStorage = (data: SSOData) =>
  store.set('sso', data);

export const getSSODataFromLocalStorage = () => store.get('sso');

export const saveCountryListToLocalStorage = data => {
  store.set(config.basic.localStorageCountryListKey, JSON.stringify(data));
};

export const getCountryListFromLocalStorage = () => {
  const data = store.get(config.basic.localStorageCountryListKey);
  if (!isEmpty(data)) {
    return JSON.parse(data);
  }
  return {};
};
