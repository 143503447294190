/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';
import { Typography, Table, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { ReduxState } from '../../../schemas';

import '../index.less';
import AddPhysiciansModal from './modals/AddPhysiciansModal';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onSubmit: Function,
  onBack: Function,
  onSkip: Function
};

const { Title, Text } = Typography;

const AddPhysicians = ({
  data,
  activeId,
  onSubmit,
  onBack,
  onSkip,
  change
}: Props) => {
  const [visible, setVisible] = React.useState(false);
  const [editedIndex, setEditedIndex] = React.useState(-1);

  const onAdd = values => {
    if (editedIndex >= 0) {
      change('addPhysicians', [
        ...data.slice(0, editedIndex),
        { id: editedIndex, ...values.physician },
        ...data.slice(editedIndex + 1, data.length)
      ]);
    } else {
      change('addPhysicians', [
        ...data,
        { id: data.length + 1, ...values.physician }
      ]);
    }
    setEditedIndex(-1);
    change('physician', {});
    setVisible(false);
  };

  const onEdit = index => {
    setVisible(true);
    setEditedIndex(index);
    change('physician', { ...data[index] });
  };

  const showModal = () => {
    setVisible(true);
  };

  const onRemove = selectedIndex => {
    change(
      'addPhysicians',
      data.filter((item, index) => index !== selectedIndex)
    );
  };

  const columns = [
    {
      title: 'Physician Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: '',
      key: 'id',
      render: (text, record) => (
        <span>
          <a style={{ marginRight: 10 }} onClick={() => onEdit(record.index)}>
            <Icon type='edit' />
          </a>
          <a onClick={() => onRemove(record.index)}>Remove</a>
        </span>
      )
    }
  ];

  const dataSource =
    data &&
    data.map((item, index) => {
      return {
        index,
        id: item.id,
        name: item.name,
        email: item.email,
        message: item.message
      };
    });

  return (
    <>
      <Title className='onboarding-action-title' level={3}>
        Add Referring Physicians
      </Title>
      <Text className='onboarding-action-subtitle'>
        Get your referring physicians on board and easily share images and
        reports with them.
      </Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey='id'
      />
      <Button
        className='onboarding-action-add-another'
        onClick={() => showModal()}
        type='dashed'
      >
        <Icon type='plus-circle' /> Add Another
      </Button>
      <AddPhysiciansModal
        visible={visible}
        setVisible={setVisible}
        onSubmit={values => onAdd(values)}
      />
      <div className='onboarding-action-buttons'>
        <Button type='link' size='large' onClick={onBack}>
          Go Back
        </Button>

        <Button type='primary' size='large' onClick={onSubmit}>
          Continue
        </Button>
      </div>
      <div className='onboarding-action-buttons'>
        <Button className='ant-btn-line' size='large' onClick={onSkip}>
          Skip for Now
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddPhysicians);
