import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import type { ReduxState, StripeInformation } from '../../schemas';
import Page from '../../components/Page';
import YourAccount from './components/YourAccount';
import PaymentInformation from './components/PaymentInformation';
import PlanDetails from './components/PlanDetails';
import PlanUpgrade from './components/PlanUpgrade';
import {
  checkStripeUnsuscribeAction,
  getStripeDataAction
} from '../../store/actions/paymentsActions';
import { showError, showSuccess } from '../../store/helpers';

import './index.less';

export default () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getStripeDataAction());
  }, []);
  const data: StripeInformation = useSelector(
    (state: ReduxState) => state.payments.data
  );
  const location = useLocation();
  React.useEffect(() => {
    if (location.search === '?stripe=success') {
      showSuccess('Stripe process completed successfully');
      dispatch(checkStripeUnsuscribeAction());
    } else if (location.search === '?stripe=error') {
      showError({
        message:
          'Something went wrong with the stripe process, please try again and contact us if the error persists'
      });
    }
  }, [location.search]);
  const { renewal, currency, balance, subscriptions, invoices, loaded } = data;
  const plan = subscriptions.main.name;
  if (!loaded) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (
    (invoices.data.length === 1 && invoices.data[0].total === 0) ||
    plan.toLowerCase().includes('lite')
  ) {
    return (
      <Page className='plan'>
        <Row>
          <PlanUpgrade currentSubscription={subscriptions.main} />
        </Row>
      </Page>
    );
  }
  return (
    <Page className='plan'>
      <Row gutter={[24, 24]} className='plan-information' type='flex'>
        <Col xs={24} lg={12}>
          <Row gutter={[0, 24]}>
            <Col>
              <YourAccount
                renewalDate={renewal}
                currency={currency}
                balance={balance}
              />
            </Col>
            <Col>
              <PaymentInformation invoiceData={invoices} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <PlanDetails currency={currency} subscriptions={subscriptions} />
        </Col>
      </Row>
    </Page>
  );
};
