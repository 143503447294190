import {
  AnonymousCredential,
  ContainerURL,
  ServiceURL,
  StorageURL
} from '@azure/storage-blob';
import AuthService from '../index';
import type { ApiService, UserProfile } from '../../schemas';
import decrypt from '../../utils/crypto';

const API_SERVICE: ApiService = 'blob-access';

// eslint-disable-next-line import/prefer-default-export
export const getSasTokenForGroupLogo = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `/options/blob-access/group`, {})
    .then(r => r);
};

export const getSasTokenForFacilityLogo = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `/options/blob-access/facility`, {})
    .then(r => r);
};

export const getTeleradSettingsContainerURL = async ({
  containerName,
  azureAddress,
  accessToken
}) => {
  const anonymousCredential = new AnonymousCredential();
  const pipeline = StorageURL.newPipeline(anonymousCredential);
  const serviceURL = new ServiceURL(
    decrypt(accessToken, azureAddress),
    pipeline
  );
  const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);

  return containerURL;
};
