import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Button, Col, Form, Row } from 'antd';
import ColorPicker from 'rc-color-picker';
import Box from 'ui-box';
import { bindActionCreators } from 'redux';
import brandingValidation from './brandingValidation';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../../../../components/FormParts';
import UploadBranding from '../branding/UploadBranding';
import { getSecondOpinionSettingRequest } from '../../../../../store/actions/secondOpinionActions';
import { ReduxState } from '../../../../../schemas';

const BRANDING_FORM_NAME = 'brandingForm';

const mapStateToProps = (state: ReduxState) => {
  return {
    initialValues: state.secondOpinion.secondOpinionSetting,
    color: state.secondOpinion.secondOpinionSetting.color
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSecondOpinionSetting: getSecondOpinionSettingRequest,
      changeField: change
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: BRANDING_FORM_NAME,
    enableReinitialize: true,
    validate: brandingValidation
  })(props => {
    // console.log('BrandingForm props', props)
    const { handleSubmit, getSecondOpinionSetting, color, changeField } = props;

    // const onUpload = useCallback(acceptedFiles => {
    //   const files = [
    //     ...logo,
    //     ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
    //   ]
    //   setLogo(files[0])
    // }, []);

    useEffect(() => {
      getSecondOpinionSetting();
    }, []);

    return (
      <Form layout='vertical' onSubmit={handleSubmit}>
        <Box marginBottom={100}>
          <Row gutter={[24, 24]}>
            <Col xs={12} lg={12} xl={12}>
              <Field name='logo_file_location' component={UploadBranding} />
              <Box marginTop={23}>
                <Row gutter={24} type='flex'>
                  <Col xs={24} sm={12}>
                    <Field
                      name='color_code'
                      label='Colour Scheme'
                      placeholder='#FFFFFF'
                      component={InputTextField}
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <Box
                      height='100%'
                      display='flex'
                      alignItems='flex-end'
                      paddingBottom='32px'
                    >
                      <ColorPicker
                        color={`#${color}`}
                        enableAlpha={false}
                        onChange={selectedColor =>
                          changeField(
                            BRANDING_FORM_NAME,
                            'color_code',
                            selectedColor.color.replace('#', '')
                          )
                        }
                      >
                        <div>
                          <Button
                            type='primary'
                            style={{ backgroundColor: 'red !important' }}
                          >
                            Select Colour
                          </Button>
                        </div>
                      </ColorPicker>
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
            <Col xs={12} lg={12} xl={12}>
              <Row gutter={48}>
                <Col xs={24}>
                  <Field
                    name='website'
                    label='Website'
                    placeholder='Input website link'
                    component={InputTextField}
                  />
                </Col>
                <Col xs={24}>
                  <Field
                    name='officeNumber'
                    label='Office Number'
                    component={InputPhoneNumberField}
                    defaultCountry='sg'
                    disableAreaCodes
                  />
                </Col>
                <Col xs={24}>
                  <Field
                    name='location'
                    label='Country'
                    placeholder='Input your country'
                    component={InputTextField}
                  />
                </Col>
                <Col xs={24}>
                  <Field
                    name='emailAddress'
                    label='Email Address'
                    type='email'
                    placeholder='Input your email address'
                    component={InputTextField}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Form>
    );
  })
);
