import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select } from 'antd';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { forEach, orderBy } from 'lodash';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import { isValidUuid } from '@azure/ms-rest-js';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../../../../components/FormParts';
import SelectField from '../../../../../components/FormParts/SelectField';
import { PHYSICIAN_SPECIALTY } from '../../../../../utils/filters';
import {
  isEmpty,
  selectFieldFilterHandler,
  setFieldOptions
} from '../../../../../utils';
import MultiSelectField from '../../../../../components/FormParts/MultiSelectField';
import AddReferringFacilities from '../../../referring-facilities/components/AddReferringFacilities';
import { resetNewlyCreatedFacility } from '../../../../../store/actions';

export const valuesToPhysician = (values, facilities) => {
  const value = values.primaryFacility[values.primaryFacility.length - 1];
  return {
    ...values,
    phone: values.phone ? values.phone.phone : undefined,
    primaryFacility:
      values.primaryFacility && values.primaryFacility.length
        ? {
            name: value,
            guid: isValidUuid(value)
              ? value
              : facilities.find(f => f.name === value).guid
          }
        : undefined
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetNewlyCreatedFacility
    },
    dispatch
  );

export default connect(state => {
  const { facilities, referringFacilities: referringFacilitiesData } = state;
  const internalFacilities = facilities.internalArray;
  const externalFacilities = facilities.externalArray;
  const referringFacilities = referringFacilitiesData.list;
  const newlyCreatedFacility = facilities.newlyCreated;

  return {
    internalFacilities,
    externalFacilities,
    referringFacilities,
    newlyCreatedFacility
  };
}, mapDispatchToProps)(
  ({
    title,
    okText,
    data,
    visible,
    onCancel,
    change,
    children,
    isInternal,
    internalFacilities,
    externalFacilities,
    referringFacilities,
    newlyCreatedFacility,
    resetNewlyCreatedFacility
  }) => {
    const [facilityModal, openFacilityModal] = useState(false);
    const [options, setOptions] = useState([]);
    const { specialty } = data || {};

    const prepareOptions = () => {
      const externalSourceFacilities = externalFacilities;
      const internalSourceFacilities = internalFacilities;

      const optionsArray = [];

      const listOfFacilityNames = [];
      // if (isInternal) {
      //   const internalOptions = {
      //     key: 'aaa',
      //     value: 'internal-facility-guid',
      //     label: 'Employing Facility (Optional)',
      //     children: []
      //   };
      //   if (internalSourceFacilities && internalSourceFacilities.length > 0) {
      //     forEach(internalSourceFacilities, ({ name }, key) => {
      //       listOfFacilityNames.push(name);
      //       internalOptions.children.push({ key, label: name, value: name });
      //     });
      //     optionsArray.push(internalOptions);
      //   }
      // } else {
      //   const externalOptions = {
      //     key: 'bbb',
      //     value: 'external-facility-guid',
      //     label: 'Other Facilities',
      //     children: []
      //   };
      //   if (externalSourceFacilities && externalSourceFacilities.length > 0) {
      //     forEach(externalSourceFacilities, ({ name }, key) => {
      //       listOfFacilityNames.push(name);
      //       externalOptions.children.push({ key, label: name, value: name });
      //     });
      //     optionsArray.push(externalOptions);
      //   }
      // }

      const internalOptions = {
        key: 'aaa',
        value: 'internal-facility-guid',
        label: t('referral_patterns.my_facilities'),
        children: []
      };

      const referringOptions = {
        key: 'rrr',
        value: 'referring-facilities',
        label: t('referral_patterns.referring_facilities'),
        children: []
      };

      if (internalSourceFacilities && internalSourceFacilities.length > 0) {
        forEach(internalSourceFacilities, ({ name, guid }, key) => {
          listOfFacilityNames.push(name);
          internalOptions.children.push({
            key: `${key}-${guid}`,
            label: name,
            value: guid
          });
        });
      }

      if (externalSourceFacilities && externalSourceFacilities.length > 0) {
        forEach(externalSourceFacilities, ({ name, guid }, key) => {
          listOfFacilityNames.push(name);
          internalOptions.children.push({
            key: `${key}-${guid}`,
            label: name,
            value: guid
          });
        });
      }

      internalOptions.children = orderBy(
        internalOptions.children,
        ['label'],
        ['asc']
      );

      optionsArray.push(internalOptions);

      if (referringFacilities && referringFacilities.length > 0) {
        forEach(referringFacilities, ({ facilityName, key: keyId }, key) => {
          listOfFacilityNames.push(facilityName);
          referringOptions.children.push({
            key: `${key}-${facilityName}`,
            label: facilityName,
            value: keyId
          });
        });
      }

      referringOptions.children = orderBy(
        referringOptions.children,
        ['label'],
        ['asc']
      );

      optionsArray.push(referringOptions);

      optionsArray.push({
        key: 'eee',
        value: 'add-new-facilities',
        label: 'Add New Facility',
        children: !isEmpty(newlyCreatedFacility)
          ? [
              {
                key: `${0}-${newlyCreatedFacility.name}`,
                label: newlyCreatedFacility.name,
                value: newlyCreatedFacility.name
              }
            ]
          : []
      });

      return optionsArray;
    };

    useEffect(() => {
      resetNewlyCreatedFacility();
    }, []);

    useEffect(() => {
      setOptions(prepareOptions());
    }, [newlyCreatedFacility]);

    return (
      <Modal
        visible={visible}
        title={title}
        okText={okText}
        onCancel={onCancel}
        destroyOnClose={false}
        footer={children}
      >
        <Form layout='vertical'>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='name'
                label={t('referral_patterns.table.name')}
                placeholder={t('referral_patterns.table.name')}
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              {specialty && specialty === 'Custom' ? (
                <Field
                  name='custom_specialty'
                  label={t('referral_patterns.table.specialty')}
                  placeholder={t('referral_patterns.table.specialty')}
                  component={InputTextField}
                />
              ) : (
                <Field
                  name='specialty'
                  label={t('referral_patterns.table.specialty')}
                  placeholder='Please select...'
                  component={SelectField}
                  componentProps={{
                    filterOption: selectFieldFilterHandler,
                    optionType: Select.Option,
                    options: setFieldOptions(PHYSICIAN_SPECIALTY),
                    showSearch: true
                  }}
                  fieldProps={{
                    targetType: 'SelectField'
                  }}
                />
              )}
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='email'
                label={t('referral_patterns.table.email_optional')}
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              <Field
                name='phone'
                label={t('referral_patterns.table.mobile_number_optional')}
                component={InputPhoneNumberField}
                countryCodeEditable={false}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
          </Row>
          <Row type='flex' align='middle' gutter={32}>
            <Col className='gutter-row' span={24}>
              <Field
                name='primaryFacility'
                label={t('referral_patterns.table.employing_facility_optional')}
                component={MultiSelectField}
                componentProps={{
                  placeholder: t('referral_patterns.table.set_the_facility'),
                  displayRender: label => label[label.length - 1],
                  expandTrigger: 'hover',
                  searchFilter: true,
                  onChange: changeVal => {
                    if (changeVal.includes('add-new-facilities')) {
                      openFacilityModal(true);
                      change('createNewFacility', true);
                    } else {
                      change('primaryFacility', changeVal);
                    }
                  },
                  options
                  // disabled: isStudyLoading
                }}
                change={change}
                fieldProps={{
                  targetType: 'CascaderField'
                }}
              />
            </Col>
          </Row>
          {facilityModal && (
            <AddReferringFacilities
              selectedTab='1'
              visible={facilityModal}
              close={() => openFacilityModal(false)}
            />
          )}
        </Form>
      </Modal>
    );
  }
);
