import type { EmptyAction } from './types';
import type {
  BankPayment,
  BillingAddress,
  /* PlanChange, */ PlanUpgrade
} from '../../schemas';

// Action strings

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const GET_PLAN_INFORMATION_REQUEST = 'GET_PLAN_INFORMATION_REQUEST';
export const GET_PLAN_INFORMATION_SUCCESS = 'GET_PLAN_INFORMATION_SUCCESS';
export const GET_PLAN_INFORMATION_FAILED = 'GET_PLAN_INFORMATION_FAILED';

export const CHANGE_CARD_INFORMATION_REQUEST =
  'CHANGE_CARD_INFORMATION_REQUEST';
export const CHANGE_CARD_INFORMATION_SUCCESS =
  'CHANGE_CARD_INFORMATION_SUCCESS';
export const CHANGE_CARD_INFORMATION_FAILED = 'CHANGE_CARD_INFORMATION_FAILED';

export const CHANGE_BILLING_ADDRESS_REQUEST = 'CHANGE_BILLING_ADDRESS_REQUEST';
export const CHANGE_BILLING_ADDRESS_SUCCESS = 'CHANGE_BILLING_ADDRESS_SUCCESS';
export const CHANGE_BILLING_ADDRESS_FAILED = 'CHANGE_BILLING_ADDRESS_FAILED';

export const UPGRADE_PLAN_REQUEST = 'UPGRADE_PLAN_REQUEST';
export const UPGRADE_PLAN_SUCCESS = 'UPGRADE_PLAN_SUCCESS';
export const UPGRADE_PLAN_FAILED = 'UPGRADE_PLAN_FAILED';

export const UPGRADE_HARDWARE_REQUEST = 'UPGRADE_HARDWARE_REQUEST';
export const UPGRADE_HARDWARE_SUCCESS = 'UPGRADE_HARDWARE_SUCCESS';
export const UPGRADE_HARDWARE_FAILED = 'UPGRADE_HARDWARE_FAILED';

export const GET_PORTAL_URL_REQUEST = 'GET_PORTAL_URL_REQUEST';
export const GET_PORTAL_URL_SUCCESS = 'GET_PORTAL_URL_SUCCESS';
export const GET_PORTAL_URL_FAILED = 'GET_PORTAL_URL_FAILED';

export const SET_BANK_PAYMENT_REQUEST = 'SET_BANK_PAYMENT_REQUEST';
export const SET_BANK_PAYMENT_SUCCESS = 'SET_BANK_PAYMENT_SUCCESS';
export const SET_BANK_PAYMENT_FAILED = 'SET_BANK_PAYMENT_FAILED';
// Action types

export type ChangeCardAction = {
  type: string,
  token: { id: string },
  error: Error
};
export type ChangeBillingAddressAction = {
  type: string,
  address: BillingAddress
};

export type UpgradePlanAction = { type: string, data: PlanUpgrade };
export type BankPaymentAction = { type: string, data: BankPayment };

export const createCustomerAction = (): EmptyAction => ({
  type: CREATE_CUSTOMER_REQUEST
});

export const getPlanInformationAction = (): EmptyAction => ({
  type: GET_PLAN_INFORMATION_REQUEST
});

export const setBankPaymentAction = (data: BankPayment): BankPaymentAction => ({
  type: SET_BANK_PAYMENT_REQUEST,
  data
});

export const changeCardInformationAction = (
  token: string,
  error: Error
): ChangeCardAction => ({
  type: CHANGE_CARD_INFORMATION_REQUEST,
  token,
  error
});

export const changeBillingAddressAction = (
  address: BillingAddress
): ChangeBillingAddressAction => ({
  type: CHANGE_BILLING_ADDRESS_REQUEST,
  address
});

export const upgradePlanAction = (data: PlanUpgrade): UpgradePlanAction => ({
  type: UPGRADE_PLAN_REQUEST,
  data
});

export const upgradeHardwareAction = (
  data: PlanUpgrade
): UpgradePlanAction => ({
  type: UPGRADE_HARDWARE_REQUEST,
  data
});

export const getPortalAction = (): EmptyAction => ({
  type: GET_PORTAL_URL_REQUEST
});
